<template lang="pug">
  nav
    .bar(:class="currentStatus")
      .plane
      template(v-for="item in getNavigationMainData")
        .nav-item
          router-link(:to="item.value" active-class="active" @click.native="$_closeNav")
            span(v-if="item.title.toLowerCase() === 'home'")
              include ../../../assets/svg/home.svg
            span(v-else) {{item.title}}
      template(v-for="lang in getNavigationLanguageData")
        .nav-item.lang-item
          router-link(:to="$_getLangLink(lang.slug)" active-class="active" @click.native="$_closeNav")
            span {{lang.slug}}
    .toggle
      button(@mouseover="$_overToggle" @mouseout="$_outToggle" @click="$_toggleNav")
        .icon
          cf-hamburger(:arrow-left="showArrowLeft" :close="showClose" :arrow-right="showArrowRight" :color="'#ef4d4b'" :height="6")
</template>

<style lang="sass" scoped>
@import '../../../styles/variables'
@import '../../../lib/core/sass/bp'

.toggle
  position: absolute
  right: 22px
  top: 31px
  button
    cursor: pointer
    border: none
    padding: 13px 10px
  +bpw('lg')
    display: none

.icon
  position: relative
  width: 30px
  height: 24px
  background: transparent

.bar
  position: fixed
  top: 0
  left: 0
  width: 100%
  text-align: center
  padding: 160px 0
  transform: translateX(100%)
  transition: opacity 0.35s ease-out, transform 0.35s ease-out
  opacity: 0
  +bpw('lg')
    opacity: 1
    transform: translateX(0%)
    top: 0
    left: auto
    right: 0
    width: auto
    padding: 16px 10px
    text-align: left
  +bpw('lg')
    padding: 18px 15px 18px 15px
  .plane
    position: absolute
    background-color: white
    top: 0
    left: 0
    width: 100%
    height: 100%
    transform: translateX(10%)
    transition: transform 0.45s ease-out
    box-shadow: 0 0 12px rgba(0, 0, 0, .10)
    +bpw('lg')
      transform: translateX(0%)
      border-bottom-left-radius: 8px
  &.is-open
    transition: opacity 0.45s ease-out
    transform: translateX(0%)
    opacity: 1
    .plane
      transform: translateX(0%)

.nav-item
  font-family: $font-main
  text-transform: uppercase
  font-weight: 600
  margin: 24px 0
  display: block
  +bpw('lg')
    margin: 0 3px
    display: inline-block
  a
    font-size: 22px
    padding: 0 14px
    letter-spacing: 0.05em
    text-decoration: none
    color: $color-copy
    position: relative
    transition: color 0.45s
    user-select: none
    +bpw('md')
      font-size: 15px
      letter-spacing: 0em
      padding: 0 6px
    +bpw('lg')
      font-size: 16px
      padding: 0 8px
    &:before
      content: ''
      position: absolute
      left: 0
      top: 0
      width: 0%
      height: 100%
      background-color: white
      transition: all 0.45s
      border-radius: 3px
    span
      position: relative
      transition: all 0.45s
      svg
        width: 18px
        height: 17px
        transform: translateY(2px)
        path
          transition: all 0.45s
    &:hover
      &:before
        content: ''
        position: absolute
        left: 0
        top: 0
        width: 100%
        height: 100%
        background-color: $color-yellow

  a.active
    &:before
      content: ''
      position: absolute
      left: 0
      top: 0
      width: 100%
      height: 100%
      background-color: $color-yellow
    color: $color-copy
    span svg path
      fill: $color-copy

.nav-item.lang-item
  display: inline-block
  margin: 24px 0
  +bpw('lg')
    margin: 0
  a
    font-size: 16px
    +bpw('md')
      font-size: 14px
      letter-spacing: 0em
      padding: 0 6px
    +bpw('lg')
      font-size: 12px
      padding: 0 4px
</style>

<script>
import {mapGetters, mapMutations} from 'vuex';
import CfHamburger from '../../../lib/ui/vue/gfx/cf_hamburger.vue';

export default {
  name: 'CwNavigation',
  components: {
    CfHamburger,
  },
  props: {},
  data () {
    return {
      showArrowLeft: false,
      showArrowRight: false,
      showClose: false,
    };
  },
  computed: {
    ...mapGetters('inreal/kirbyApi', [
      'getLanguages',
      'getSiteData',
      'getPageData',
    ]),
    ...mapGetters('nav', [
      'getOpen',
    ]),
    getNavigationMainData () {
      return this.getSiteData.navigation.main;
    },
    getNavigationLanguageData () {
      return this.getLanguages;
    },
    currentStatus () {
      return {
        'is-open': this.getOpen,
      };
    },
  },
  watch: {},
  mounted () {},
  beforeDestroy () {},
  methods: {
    ...mapMutations('nav', [
      'toggleOpen',
      'setOpen',
    ]),
    $_getLangLink (slug) {
      return this.getPageData.head.translations[slug];
    },
    $_overToggle () {
      if (this.getOpen) {
        this.showArrowLeft = false;
        this.showArrowRight = true;
        this.showClose = false;
      } else {
        this.showArrowLeft = true;
        this.showArrowRight = false;
        this.showClose = false;
      }
    },
    $_outToggle () {
      if (this.getOpen) {
        this.showArrowLeft = false;
        this.showArrowRight = false;
        this.showClose = true;
      } else {
        this.showArrowLeft = false;
        this.showArrowRight = false;
        this.showClose = false;
      }
    },
    $_toggleNav () {
      this.toggleOpen();
    },
    $_closeNav () {
      this.showArrowLeft = false;
      this.showArrowRight = false;
      this.showClose = false;
      this.setOpen(false);
    },
  },
};
</script>
