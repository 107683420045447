<template lang="pug">
  .cw-picture(:class="{'fullsize': isFullsize, 'portrait': isPortrait, 'loaded': isLoaded}")
    picture(
      @click="clickHandler($event)"
      @mouseover="mouseOverHandler($event)"
      @mouseleave="mouseLeaveHandler($event)")
      template(v-for="bp in sourceBreakpoints")
        source(:srcset="getSrcset(curFile, curSize, bp)" :media="getMedia(bp)")
      img(:src="getSrcset(curFile, curSize, imageBreakpoint)" :alt="alt" ref="img")
</template>


<style lang="sass">
.cw-picture
  font-size: 0
  line-height: 0
  opacity: 0
  transition: all 0.45s
  user-select: none

.loaded
  opacity: 1

picture, img, source
  transition: opacity 1.45s
  width: 100%
  height: auto
.fullsize,
.fullsize picture
  position: absolute
  left: 0
  top: 0
  width: 100%
  height: 100%
  source, img
    position: absolute
    left: 50%
    width: auto
    height: 100%
    transform: translateX(-50%)

.fullsize.portrait,
.fullsize.portrait picture
  source, img
    position: absolute
    left: 0%
    top: 50%
    width: 100%
    height: auto
    transform: translateY(-50%)
</style>

<script>
import imagesConfig from '../../config/images.json';
import {breakpoints} from '../../config/style.json';
import {mapGetters} from 'vuex';
import {escape, path} from '../../api/utils/convert';
import {isString} from '../../api/utils/validate';

import {isObject} from '../../lib/core/js/object';
import {isArray} from '../../lib/core/js/array';
import {hasKey} from '../../lib/core/js/collections';
import {waitFor} from '../../lib/core/js/time';

export default {
  name: 'CwPicture',
  props: {

    // the image object
    file: {
      type: Object,
      required: true,
    },

    // optional
    fileHover: null,

    // mixed - either a node from images.json or
    // the array with the scaling config
    size: {},

    // optional
    sizeHover: null,
    isFullsize: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      isHovered: false,
      isLoaded: false, // only false on first load
      isPortrait: false,
    };
  },
  computed: {
    ...mapGetters('app', [
      'getBreakpointKey',
      'getBreakpointValue',
      'getDocumentRatio',
      'getDocumentWidth',
      'getDocumentHeight',
    ]),
    breakpoint () {
      return this.getBreakpointKey;
    },
    availableBreakpoints () {
      return imagesConfig.available ? imagesConfig.available : ['xs', 'md', 'lg'];
    },
    sourceBreakpoints () {
      const keys = [];
      this.availableBreakpoints.forEach(key => {
        if (this.has(this.curSize, key)) {
          keys.push(key);
        }
      });
      return keys;
    },
    imageBreakpoint () {
      return this.sourceBreakpoints[this.sourceBreakpoints.length - 1];
    },
    alt () {
      let alt = this.file.content && this.file.content.alt ? this.file.content.alt : '';
      return escape(alt);
    },
    curFile () {
      return (this.fileHover && this.isHovered) ? this.fileHover : this.file;
    },
    curSize () {
      return (this.sizeHover && this.isHovered) ? this.sizeHover : this.size;
    },
    boundingBoxWidth () {
      return this.isLoaded ? this.$el.offsetWidth : 0;
    },
  },
  watch: {
    'getDocumentWidth': 'updateRatio',
    'getDocumentHeight': 'updateRatio',
  },
  created () {
    this.preloadHandler();
  },
  methods: {
    updateRatio () {
      if (this.isFullsize && this.isLoaded) {
        if (this.$el.offsetWidth >= this.$refs['img'].width) {
          this.isPortrait = true;
          if (this.$el.offsetHeight > this.$refs['img'].height) {
            this.isPortrait = false;
          }
        } else {
          this.isPortrait = false;
        }
      }
    },
    has (size, breakpoint) {
      if (isString(size)) {
        size = path(imagesConfig, size);
      }
      const res = isObject(size) ? hasKey(size, breakpoint) : isArray(size) && breakpoint === 'lg';
      return res;
    },
    getSrcset (file, size, breakpoint) {
      let image = this.$image.get(
        file,
        this.getConfig(size, breakpoint),
        'auto',
      );
      return isObject(image) ? image.src : '';
    },
    getMedia (breakpoint) {
      return `(max-width: ${breakpoints[breakpoint]}px)`;
    },
    getConfig (size, breakpoint) {
      let res = size;
      if (isString(res)) {
        res += `.${breakpoint}`;
      }
      return res;
    },

    /**
     * preload a single image
     * because image config (=size) may not have entries for every
     * breakpoint, breakpoints as a list from small to big are given
     * to detect the best config
     * @param {Object} file
     * @param {*} size
     * @param {Array} breakpoints
     * @returns {Promise}
     */
    preloadImage (file, size, breakpoints) {
      for (let i = 0; i < breakpoints.length; i++) {
        if (this.has(size, breakpoints[i])) {
          return this.$image.preload(
            file,
            this.getConfig(size, breakpoints[i]),
            'auto',
          );
        }
      }
      return new Promise(resolve => {
        resolve();
      });
    },
    preloadHandler () {
      const promises = [];

      // get all breakpoints from current to bigger
      let breakpoints = this.availableBreakpoints;
      breakpoints = breakpoints.slice(breakpoints.indexOf(this.breakpoint));

      // preload regular image
      promises.push(this.preloadImage(this.file, this.size, breakpoints));

      // preload hover image, if any
      if (this.fileHover != null || this.sizeHover != null) {
        promises.push(this.preloadImage(
          this.fileHover || this.file,
          this.sizeHover || this.size,
          breakpoints,
        ));
      }
      Promise.all(promises)
        .then(() => {
          this.isLoaded = true;
          this.$emit('load', new CustomEvent('load', {
            bubbles: true,
            cancelable: true,
            detail: true,
          }));
          waitFor(250);
          this.updateRatio();
        })
        .catch(() => {
          this.$emit('load', new CustomEvent('load', {
            bubbles: true,
            cancelable: true,
            detail: false,
          }));
        });
    },
    clickHandler (event) {
      this.$emit('click', event);
    },
    mouseOverHandler (event) {
      event.stopPropagation();
      this.isHovered = true;
      this.$emit('mouseover', event);
    },
    mouseLeaveHandler (event) {
      event.stopPropagation();
      this.isHovered = false;
      this.$emit('mouseleave', event);
    },
  },
};
</script>

