export function isInteger (val, min, max) {
  return isNumber(val, min, max) && Math.floor(val) === val;
}

export function isFloat (val, min, max) {
  return isNumber(val, min, max) && Math.floor(val) !== val;
}

export function isNumber (val, min = null, max = null) {
  let type = typeof val;
  if (type && type === 'number' && isFinite(val)) {
    if (!isNumber(min)) {
      min = val;
    }
    if (!isNumber(max)) {
      max = val;
    }
    return (val >= min && val <= max);
  }
  return false;
}

export function isBool (value) {
  return (value === true || value === false);
}

export function isEmpty (value) {
  return value === '' || value == null || typeof value === 'undefined';
}

/**
 * @param {string} value
 * @param {int} min
 * @param {int} max
 * @returns {boolean}
 */
export function isString (value, min = 1, max = null) {
  if (typeof value && typeof value === 'string') {
    if (!max) {
      max = value.length;
    }
    return value.length >= min && value.length <= max;
  }
  return false;
}

export function isFunction (val) {
  return val && {}.toString.call(val) === '[object Function]';
}
