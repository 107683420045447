import {isArray} from '../array';
import {isMap} from '../map';
import {isObject} from '../object';

/**
 * Converts an object to a Map.
 *
 * @param {!Object<?, ?>} object
 * @returns {Map}
 */
export function objectToMap (object) {
  const map = new Map();
  for (const [key, value] of Object.entries(object)) {
    map.set(key, value);
  }
  return map;
}

/**
 * Converts a Map to an object.
 *
 * @param {!Map} map
 * @returns {Object}
 */
export function mapToObject (map) {
  const object = {};
  map.forEach((value, key) => {
    object[key] = value;
  });
  return object;
}

/**
 * Proofs if Object/Array/Map contains key-value pair.
 *
 * @param {Object|Array|Map} obj
 * @param {string|number} key
 * @param {*=} value
 * @returns {boolean}
 */
export function hasKey (obj, key, value = null) {
  if (isArray(obj) && obj[key] != null) {
    return value != null ? obj[key] === value : true;
  } else if (isObject(obj) && Object.prototype.hasOwnProperty.call(obj, key)) {
    return value != null ? obj[key] === value : true;
  } else if (isMap(obj) && obj.has(key)) {
    return value != null ? obj.get(key) === value : true;
  }
  return false;
}

/**
 * Get value in Object/Array/Map by key.
 *
 * @param {Object|Array|Map} obj
 * @param {string|number} key
 * @returns {*}
 */
export function getByKey (obj, key) {
  if (Array.isArray(obj) && obj[key] != null) {
    return obj[key];
  } else if (isObject(obj) && Object.prototype.hasOwnProperty.call(obj, key)) {
    return obj[key];
  } else if (isMap(obj) && obj.has(key)) {
    return obj.get(key);
  }
  return null;
}

/**
 * Set value in Object/Array/Map by key.
 *
 * @param {Object|Array|Map} obj
 * @param {string|number} key
 * @param {*} value
 */
export function setKeyValue (obj, key, value) {
  if (isArray(obj)) {
    obj.push(value);
  } else if (isObject(obj)) {
    obj[key] = value;
  } else if (isMap(obj)) {
    obj.set(key, value);
  }
}
