import {toInteger} from '../utils/convert';

const state = {
  cache: new Map(),
};

const getters = {
  get: state => id => {
    if (state.cache.has(id)) {
      return state.cache.get(id);
    } else {
      return null;
    }
  },
};

const mutations = {
  set (state, params) {
    state.cache.set(params.id, params.data);
    if (state.cache.size > toInteger(process.env.VUE_APP_CACHESIZE)) {
      let first = state.cache.keys().next().value;
      state.cache.delete(first);
    }
  },
};

const actions = {};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
