<template lang="pug">
  header
    slot
    .wrapper-logo
      cw-logo
    cw-cta(v-if="showCta")
</template>

<style lang="sass" scoped>
@import '../../lib/core/sass/bp'

header
  position: fixed
  left: 0
  right: 0
  width: 100%
  z-index: 100

.wrapper-logo
  position: absolute
  top: 25px
  left: 25px
  width: 180px
  height: 62px
  +bpw('lg')
    top: 55px
    left: 75px
    width: 199px
    height: 66px
</style>

<script>
import CwLogo from '../elements/cw_logo';
import CwCta from '../elements/cw_cta';
import {mapGetters} from 'vuex';
export default {
  name: 'CwHeader',
  components: {
    CwLogo,
    CwCta,
  },
  props: {},
  data () {
    return {};
  },
  computed: {
    ...mapGetters('inreal/kirbyApi', [
      'getSiteData',
      'getPageData',
    ]),
    showCta () {
      let showCta = false;
      if (!(this.getSiteData.cta.page != null && this.getSiteData.cta.page.value === this.$route.path)) {
        showCta = true;
      }
      if (this.getPageData.content != null && this.getPageData.content.hidecta != null && this.getPageData.content.hidecta === true) {
        showCta = false;
      }
      return showCta;
    },
  },
};
</script>

