<template lang="pug">
  #app
    cw-header
      cw-navigation
    main.cw-base
      cf-consent-manager(#default="{enableEditing}" primary-color="#D08460" :opens-initially="true")
        cw-privacy(@edit-settings-click="enableEditing")
      transition(name="fade" mode="out-in")
        component(:is="$_currentTemplate" :key="$_currentKey" class="cw-template")
    cw-modal(v-if="Object.values(getModalData).length > 0")
</template>

<style lang="sass">
// @import url("https://use.typekit.net/hzo1nwm.css")

@import url("https://p.typekit.net/p.css?s=1&k=hzo1nwm&ht=tk&f=10294.10295.10296.10297&a=15774936&app=typekit&e=css")

@import '~normalize.css/normalize'
@import '../../lib/core/sass/button-reset'
@import '../../../node_modules/swiper/css/swiper.css'
@import '../../styles/variables'
@import '../../styles/globals'
@import '../../styles/slider'


@font-face
  font-family: "brandon-grotesque"
  src: url("../../assets/fonts/0000000000000000000132df.woff2") format("woff2"), url("../../assets/fonts/0000000000000000000132df.woff") format("woff"), url("../../assets/fonts/0000000000000000000132df.otf") format("opentype")
  font-display: auto
  font-style: normal
  font-weight: 400


@font-face
  font-family: "brandon-grotesque"
  src: url("../../assets/fonts/0000000000000000000132e0.woff2") format("woff2"), url("../../assets/fonts/0000000000000000000132e0.woff") format("woff"), url("../../assets/fonts/0000000000000000000132e0.otf") format("opentype")
  font-display: auto
  font-style: italic
  font-weight: 400


@font-face
  font-family: "brandon-grotesque"
  src: url("../../assets/fonts/0000000000000000000132e3.woff2") format("woff2"), url("../../assets/fonts/0000000000000000000132e3.woff") format("woff"), url("../../assets/fonts/0000000000000000000132e3.otf") format("opentype")
  font-display: auto
  font-style: normal
  font-weight: 700


@font-face
  font-family: "brandon-grotesque"
  src: url("../../assets/fonts/0000000000000000000132e4.woff2") format("woff2"), url("../../assets/fonts/0000000000000000000132e4.woff") format("woff"), url("../../assets/fonts/0000000000000000000132e4.otf") format("opentype")
  font-display: auto
  font-style: italic
  font-weight: 700


.tk-brandon-grotesque
  font-family: "brandon-grotesque", sans-serif


/* cyrillic-ext */
@font-face
  font-family: 'Lora'
  font-style: normal
  font-weight: 400
  font-display: swap
  src: url(../../assets/fonts/0QI6MX1D_JOuGQbT0gvTJPa787weuxJMkqt8ndeYxZ2JTg.woff) format('woff')
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F

/* cyrillic */
@font-face
  font-family: 'Lora'
  font-style: normal
  font-weight: 400
  font-display: swap
  src: url(../../assets/fonts/0QI6MX1D_JOuGQbT0gvTJPa787weuxJFkqt8ndeYxZ2JTg.woff) format('woff')
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116

/* vietnamese */
@font-face
  font-family: 'Lora'
  font-style: normal
  font-weight: 400
  font-display: swap
  src: url(../../assets/fonts/0QI6MX1D_JOuGQbT0gvTJPa787weuxJOkqt8ndeYxZ2JTg.woff) format('woff')
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB

/* latin-ext */
@font-face
  font-family: 'Lora'
  font-style: normal
  font-weight: 400
  font-display: swap
  src: url(../../assets/fonts/0QI6MX1D_JOuGQbT0gvTJPa787weuxJPkqt8ndeYxZ2JTg.woff) format('woff')
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF

/* latin */
@font-face
  font-family: 'Lora'
  font-style: normal
  font-weight: 400
  font-display: swap
  src: url(../../assets/fonts/0QI6MX1D_JOuGQbT0gvTJPa787weuxJBkqt8ndeYxZ0.woff) format('woff')
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD

button
  @include button-reset

.cw-base

.cw-template
  padding-top: 0
  padding-bottom: 0
  transition: all .4s cubic-bezier(.55, 0, .1, 1)

</style>

<style lang="sass" scoped>
@import '../../styles/variables'
@import '../../lib/core/sass/bp'

.fade-enter-active,
.fade-leave-active
  transition: opacity .5s ease
.fade-enter,
.fade-leave-active
    opacity: 0

.cf-consent-manager
  &::v-deep
    .btn-agree
      text-align: center
    .intro
      font-size: 13px
      +bpw('xl')
        font-size: 15px
    header h1
      display: none
      color: $color-grey-dark
      line-height: 24px
    button
      font-family: $font-main
      text-transform: uppercase
    .consent-list
      margin-top: 12px
    label
      font-family: $font-main
      text-transform: uppercase
      font-weight: 600
</style>

<script>
/**
 * Base Template is the universal entry point for ALL pages
 * It loades the page-specific template which then again loades the page
 */

// eslint-disable-next-line
import styles from '../../styles/variables.sass'

import CwHeader from '../structure/cw_header';
import CwFooter from '../structure/cw_footer';
import CwCta from '../elements/cw_cta';
import CwModal from '../structure/cw_modal';
import CwNavigation from '../structure/navigation/cw_navigation';
import '../../lib/consent-manager/consent-manager.css';
import {CfConsentManager} from '../../lib/consent-manager';
import CwPrivacy from './cw_privavy';
import {hasPiwikLoaded, setTracker, getUserAttribute} from '../../tracking';
import {mapGetters} from 'vuex';

export default {
  name: 'CwBase',
  components: {
    CfConsentManager,
    CwHeader,
    CwNavigation,
    CwFooter,
    CwCta,
    CwPrivacy,
    CwModal,
  },
  computed: {
    ...mapGetters('inreal/kirbyApi', [
      'getModalData',
    ]),
    $_currentTemplate () {
      return this.$router.getTemplate();
    },
    $_currentKey () {
      return `cw-template-${this.$router.getBlueprint()}`;
    },
  },
  async mounted () {
    await Promise.all([
      await hasPiwikLoaded(10000),
      await setTracker(),
      await getUserAttribute(),
    ]);
  },
  methods: {
    $_onOpenPrivacyModalClick () {
      this.$store.commit('inreal/consentManager/open', {mode: 'full-text'});
    },
  },
};
</script>


