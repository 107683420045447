import axios from 'axios';
import {isObject} from '../lib/core/js/object';
import {isArray} from '../lib/core/js/array';
import {isMap} from '../lib/core/js/map';
import {hasKey, setKeyValue, getByKey} from '../lib/core/js/collections';
import {isInteger, isString} from './utils/validate';
import {toUrl, toBool, toFloat, timeStringToTimestamp, dateStringToTimestamp} from './utils/convert';
import {getBrowserLanguage} from '../lib/core/js/i18n';

import apiStore from './store/root.js';
import cacheStore from './store/cache.js';

const API = 'inreal/kirbyApi';
const CACHE = 'inreal/kirbyCache';

const FIELD_TYPE_ARRAY = 'array';
const FIELD_TYPE_BOOL = 'bool';
const FIELD_TYPE_DATE = 'date';
const FIELD_TYPE_EMAIL = 'email';
const FIELD_TYPE_FILES = 'files';
const FIELD_TYPE_HTML = 'html';
const FIELD_TYPE_MULTISELECT = 'multiselect';
const FIELD_TYPE_NUMBER = 'number';
const FIELD_TYPE_PAGES = 'pages';
const FIELD_TYPE_SELECT = 'select';
const FIELD_TYPE_STRING = 'string';
const FIELD_TYPE_TEL = 'tel';
const FIELD_TYPE_TIME = 'time';
const FIELD_TYPE_URL = 'url';
const FIELD_TYPE_USERS = 'users';

class ApiPlugin {
  constructor () {
    this.cancelTokens = {};
  }

  /*
  |--------------------------------------------------------------------------
  | Register
  |--------------------------------------------------------------------------
  */

  install (Vue, options) {
    if (!options || !options.store) {
      throw new Error('Please initialise plugin with a Vuex store.');
    }
    options.store.registerModule(API, apiStore);
    options.store.registerModule(CACHE, cacheStore);

    this.$store = options.store;
    this.$store.commit(`${API}/setEnviromentVars`);

    if (!options || !options.i18n) {
      throw new Error('Please initialise plugin with a vue-i18n.');
    }
    this.$i18n = options.i18n;

    Vue.prototype.$api = {
      languages: () => {
        return this.languages();
      },
      translations: lang => {
        return this.translations(lang);
      },
      site: lang => {
        return this.site(lang);
      },
      node: (slug, query, lang) => {
        return this.node(slug, query, lang);
      },
      children: (slug, request, lang) => {
        return this.children(slug, request, lang);
      },
      static: url => {
        return this.static(url);
      },
      submit: (action, data, lang) => {
        return this.submit(action, data, lang);
      },
      cancel: () => {
        this.cancel();
      },
    };
  }

  /*
  |--------------------------------------------------------------------------
  | Interface
  |--------------------------------------------------------------------------
  */

  /**
   * Get available languages for the site
   * @returns {Promise}
   */
  languages () {
    return this._get(toUrl(this._getApiRoute(), 'languages'), {}, false);
  }

  /**
   * Get translation (terms) for given language. Terms are defined in Kirby's
   * language file.
   * @param {string} lang
   * @returns {Promise}
   */
  translations (lang) {
    lang = lang || this._getCurrentLanguage();
    return this._get(toUrl(this._getApiRoute(), 'translations', lang), {}, false);
  }

  /**
   * Get site content including navigation
   * @param {string} lang
   * @returns {Promise}
   */
  site (lang) {
    lang = lang || this._getCurrentLanguage();
    return this._get(toUrl(this._getApiRoute(), 'site', lang), {}, true);
  }

  /**
   * Get page/node content
   * @param {string} slug
   * @param {Object} query
   * @param {string} lang, can be empty
   * @returns {Promise}
   */
  node (slug, query, lang) {
    lang = lang || this._getCurrentLanguage();
    return this._get(toUrl(this._getApiRoute(), 'node', lang, slug), query, true);
  }

  /**
   * Get page/node children
   *
   * {integer} page
   * {integer} limit
   * {string} order asc|desc
   * {array} fields [field1, field2]
   * {array} filter [name.eq.value,...], operators: eq, nt, gt, gte, lt, lte | values: ..., today
   * {string} search, not implemented, reserved for free-text-search
   *
   * @param {string} slug
   * @param {Object} request, tranlated to query
   * @param {string} lang, can be empty
   * @returns {Promise}
   */
  children (slug, request, lang) {
    lang = lang || this._getCurrentLanguage();
    request = isObject(request) ? request : {};
    let query = {
      page: isInteger(request.page, 1) ? request.page : 1,
      limit: isInteger(request.limit, 1) ? request.limit : 10,
      order: isString(request.order) && request.order === 'desc' ? 'desc' : 'asc',
      fields: isArray(request.fields) ? request.fields : 'all',
      filter: isArray(request.filter) ? request.filter : [],
    };

    // no cache so far, because query may change and query would need to be
    // added to cache key
    return this._get(toUrl(this._getApiRoute(), 'children', lang, slug), query, false);
  }

  /**
   * Get a static url
   * @param {string} url
   * @returns {Promise}
   */
  static (url) {
    return this._getStatic(url);
  }

  /**
   * @param {string} action the preset in Kirby's email config
   * @param {Object} data, the data to post, flat object
   * @param {string} lang
   * @returns {Promise}
   */
  submit (action, data, lang) {
    return this._post(toUrl(this._getApiRoute(), 'submit', lang, action), data);
  }

  /**
   * Cancel all pending requests
   */
  cancel () {
    this._cancel();
  }

  /*
  |--------------------------------------------------------------------------
  | API calls
  |--------------------------------------------------------------------------
  */

  /**
   * API get-request
   * @param {string} url
   * @param {Object} query
   * @param {bool} cacheIfEnabled
   * @returns {Promise}
   */
  _get (url, query, cacheIfEnabled) {
    // info(`api request for ${url}`);
    // get from cache
    if (cacheIfEnabled && toBool(process.env.VUE_APP_API_CACHE)) {
      let data = this.$store.getters[`${CACHE}/get`](this._urlId(url));
      if (isObject(data)) {
        return new Promise(resolve => {
          resolve({
            status: 200,
            data,
          });
        });
      }
    }
    // request
    return axios.get(url, {
      params: query || {},
      responseEncoding: 'utf8',
      cancelToken: this._getCancelToken(url),
    })
      .then(response => {
        let data = this._parseResponse(response.data);
        if (cacheIfEnabled && toBool(process.env.VUE_APP_API_CACHE)) {
          this.$store.commit(`${CACHE}/set`, {
            id: this._urlId(url),
            url,
            data,
          });
        }
        return {
          status: 200,
          data,
        };
      })
      .catch(error => {
        return Promise.reject(new Error(error, {
          status: this._getErrorStatus(error, url),
        }));
      });
  }

  _post (url, data) {
    // info(`api post request for ${url}`);
    return axios.post(url, data, {
      responseEncoding: 'utf8',
      cancelToken: this._getCancelToken(url),
    })
      .then(response => {
        let data = this._parseResponse(response.data);
        this._info(data);
        return {
          status: 200,
          data,
        };
      })
      .catch(error => {
        return Promise.reject(new Error({
          status: this._getErrorStatus(error, url),
        }));
      });
  }

  /**
   * @param {string} url
   * @returns {Promise}
   */
  _getStatic (url) {
    info(`api static request for ${url}`);
    return axios.get(url, {
      cancelToken: this._getCancelToken(url),
    })
      .then(response => {
        return response;
      })
      .catch(error => {
        return Promise.reject(new Error({
          status: this._getErrorStatus(error, url),
        }));
      });
  }

  /*
  |--------------------------------------------------------------------------
  | API Helper
  |--------------------------------------------------------------------------
  */

  _getApiRoute () {
    return this.$store.getters[`${API}/getApiRoute`];
  }

  _getCurrentLanguage () {
    return this.$store.getters[`${API}/getCurrentLanguage`]('slug');
  }

  /**
   * @param {string} url
   * @returns {Cancel}
   */
  _getCancelToken (url) {
    let id = this._urlId(url);
    return new axios.CancelToken(cancel => {
      this.cancelTokens[id] = cancel;
    });
  }

  /**
   * Cancel the request for a given url or all requests if url is empty
   * @param {string} url, optional
   */
  _cancel (url) {
    if (isString(url)) {
      let id = this._urlId(url);
      if (this.cancelTokens[id]) {
        this.cancelTokens[id]();
      }
    } else {
      Object.values(this.cancelTokens).forEach(cancel => {
        cancel();
      });
    }
  }

  /**
   * Submethod to determine status code
   * @param {Object} err, api response
   * @param {string} url
   * @returns {Promise}
   */
  _getErrorStatus (err, url) {
    let status;
    let msg = '';

    // canceled by user/app
    if (axios.isCancel(err)) {
      msg = 'Request canceled';
      status = 499;
    } else if (err.response && err.response.status) {
      status = err.response.status;
      if (err.response.data && err.response.data.msg) {
        msg = `Server said: ${err.response.data.msg}`;
      }

      // no response
    } else if (err.request) {
      status = 504;

      // request error
    } else {
      status = 500;
    }
    if (!msg) {
      msg = `HTTP-error ${status} on calling gateway ${url}`;
    }
    console.error(Object.values(msg).join(''), Object.values(status).join(''));
    return status;
  }

  /*
  |--------------------------------------------------------------------------
  | Language Interface
  |--------------------------------------------------------------------------
  */

  setLocale (lang, data) {
    this.$i18n.mergeLocaleMessage(lang, data);
    this.$i18n.locale = lang;
  }

  setLocaleFallback (lang) {
    if (!this.$i18n.fallbackLocale) {
      this.$i18n.fallbackLocale = lang;
    }
  }

  detectLanguage (languages) {
    let lang = '';
    let langDefault;

    // get 1. from url
    let url = typeof window.location.pathname === 'string' ? window.location.pathname : '/';
    Object.keys(languages).forEach(key => {
      const language = languages[key];
      if (language.default) {
        langDefault = key;
      }
      if (url.substr(0, key.length + 2) === `/${key}/` || url === `/${key}`) {
        // detects /en, /en/ and /en/home, but not /england
        lang = key;
      }
    });

    // get 2. from browser-language,
    // but only if no path is given
    if (url === '/') {
      if (!this.isLanguageValid(lang, languages) && getBrowserLanguage() != null) {
        lang = getBrowserLanguage();
      }
    }
    // get default
    if (!this.isLanguageValid(lang, languages)) {
      lang = langDefault;
    }
    return lang;
  }

  isLanguageValid (lang, languages) {
    return typeof lang === 'string' && Object.keys(languages).find(key => key === lang) != null;
  }

  /*
  |--------------------------------------------------------------------------
  | Content Helper
  |--------------------------------------------------------------------------
  */

  /**
   * @param {Object} obj nodes
   * @returns {Object}
   */
  _parseResponse (obj) {
    Object.keys(obj).forEach(name => {
      const node = obj[name];
      if (isObject(node) || isArray(node) || isMap(node)) {
        if (hasKey(node, 'value') && hasKey(node, 'type')) {
          switch (getByKey(node, 'type')) {
            case FIELD_TYPE_ARRAY:
            case FIELD_TYPE_SELECT:
            case FIELD_TYPE_MULTISELECT:
            case FIELD_TYPE_PAGES:
            case FIELD_TYPE_USERS:
              setKeyValue(obj, name, node.value);
              break;
            case FIELD_TYPE_FILES:
              this._parseResponse(node);
              setKeyValue(obj, name, node.value);
              break;
            case FIELD_TYPE_NUMBER:
              setKeyValue(obj, name, toFloat(node.value));
              break;
            case FIELD_TYPE_HTML:
              Object.values(node.value).forEach(block => {
                let html = block.html;
                html = this._setRouterLinks(html);
                setKeyValue(block, 'html', html);
              });
              setKeyValue(obj, name, node.value);
              break;
            case FIELD_TYPE_BOOL:
              setKeyValue(obj, name, toBool(node.value));
              break;
            case FIELD_TYPE_DATE:
              setKeyValue(obj, name, dateStringToTimestamp(node.value));
              break;
            case FIELD_TYPE_TIME:
              setKeyValue(obj, name, timeStringToTimestamp(node.value));
              break;
            case FIELD_TYPE_URL:
            case FIELD_TYPE_EMAIL:
            case FIELD_TYPE_TEL:
              break;
            case FIELD_TYPE_STRING:
              setKeyValue(obj, name, node.value);
              break;
          }
        } else {
          this._parseResponse(node);
        }
      }

    });
    return obj;
  }

  /**
   * replace intern links with <router-link> to let the router handle it
   * @param {string} html
   * @returns {string}
   */
  _setRouterLinks (html) {
    let regex = /<a.+?data-link-intern.*?>(.*?)<\/a>/gi;
    html = html.replace(regex, (match, text) => {
      // don't extract in first regex, because it's not possible to know,
      // if href is before or after data-link-extern
      let regex = /href=["|'](.*?)["|']/i;
      let href = regex.exec(match);
      let url = isArray(href) ? href[1] : '/';
      return `<router-link to="${url}" data-link-intern>${text}</router-link>`;
    });
    return html;
  }

  /**
   * @param {string} uri
   * @returns {string}
   */
  _urlId (uri) {
    let hash = uri.replace(/[?|&|/|=]/g, '_');
    hash = hash.replace(/\[\]/g, '');
    return hash;
  }

  /**
   * Logging depending on settings in /config/logger.json
   * @param {mixed} obj
   */
  _info (obj) {
    if (toBool(process.env.VUE_APP_LOG_API)) {
      console.log(obj);
    }
  }
}

export default new ApiPlugin();
