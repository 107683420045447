<template lang="pug">
  .cf-consent-manager(:class="{'show-details': mode === 'full-text' || showDetails, 'is-editing': editing}")
    .backdrop
    transition(:css="false" @enter="$_onEnter" @leave="$_onLeave")
      .root(
        v-if="mode != null"
        ref="root"
      )
        section.consent
          header
            h1 {{$$t('title')}}
            slot(name="header")
            button.btn-close(
              v-if="mode === 'full-text' || hasCloseButton"
              :title="$$t('closeButtonTitle')"
              @click="$_onCloseClick"
            )
              | &times;
          template(v-if="mode === 'minimal' || editing")
            p.intro
              | {{$$t('intro')}}
              slot(name="intro")
            ul.consent-list(v-if="editing && allowSelection")
              li(v-for="({type}, i) in localConsentTypes")
                label
                  input.consent-checkbox(type="checkbox" v-model="localConsentTypes[i].status")
                  | {{$$t(`consentTypes.${type}`)}}
            .actions
              slot(name="actions")
              button.btn-show-details(
                v-if="mode === 'minimal' && (editing || !allowSelection)"
                :style="secondaryButtonStyles"
                @click="$_onDetailsLinkClick"
              ) {{$$t('showDetailsLinkText')}}
              template(v-if="allowSelection")
                button(
                  v-if="editing"
                  :style="colorStyles"
                  @click="$_onAgreeSelectionClick"
                ) {{$$t('agreeToSelection')}}
                button(
                  v-else-if="mode === 'minimal'"
                  :style="colorStyles"
                  @click="enableEditing"
                ) {{$$t('editSelection')}}

              button(
                v-if="hasDeclineButton || !allowSelection"
                :style="colorStyles"
                @click="$_onDeclineClick"
              ) {{$$t('declineAll')}}
              button.btn-agree(
                :style="{backgroundColor: primaryColor}"
                @click="$_onAgreeAllClick"
              ) {{(editing || !allowSelection) ? $$t('agreeToAll') : $$t('agree')}}
        transition(
          :css="false"
          @before-enter="$_onDetailsBeforeEnter"
          @enter="$_onDetailsEnter"
          @leave="$_onDetailsLeave"
        )
          section.details-text(v-if="mode === 'full-text' || showDetails")
            cf-scrollbar(:show-y-start-indicator="false")
              .details-text-padding
                slot(:enable-editing="enableEditing")
</template>

<style scoped lang="sass">
@use '../../../lib/core/sass/bp' as *

$blue: #1278ef
$primary: #343434
$hairlineColor: #e6e6e6
$padding: 18px

.cf-consent-manager
  color: $primary
  font-size: 14px
  overflow: hidden
  position: fixed
  top: 0
  left: 0
  width: 100%
  height: 100%
  z-index: 1000
  pointer-events: none

::v-deep .cf-scrollbar
  width: 100%

.root
  position: absolute
  bottom: 0
  max-width: 640px
  left: 0
  right: 0
  margin: 0 auto
  background: white
  box-shadow: 0 0 2px 1px rgba(black, 0.15), 0 0 44px 20px rgba(black, 0.1)
  border-radius: 8px 8px 0 0
  display: flex
  overflow: hidden
  flex-direction: column
  max-height: calc(100% - 50px)
  transition: box-shadow .2s ease
  pointer-events: all

.backdrop
  position: absolute
  top: 0
  left: 0
  right: 0
  bottom: 0
  background: rgba(black, .4)
  pointer-events: none
  opacity: 0
  backdrop-filter: blur(24px)
  transition: opacity .3s ease

  .show-details &
    opacity: 1
    pointer-events: all

a
  color: $blue

button
  appearance: none
  border: 0
  background: none
  padding: 0
  cursor: pointer

  &:active
    transform: translateY(1px)

.consent
  padding: $padding
  background: white
  border-bottom: 1px solid $hairlineColor
  box-shadow: 0 1px 4px rgba(black, 0.05), 0 4px 24px rgba(black, 0.04)
  z-index: 2

  > header
    display: flex
    align-items: baseline

    h1
      margin: 0.2em 0 0
      font-size: 12px
      text-transform: uppercase
      letter-spacing: 0.08em

.intro
  line-height: 1.3

.actions
  display: flex
  align-items: baseline
  margin-top: .5em
  flex-wrap: wrap
  justify-content: center

  +bpw('sm')
    flex-wrap: nowrap
    justify-content: normal

  > button
    color: $blue
    flex: 0 0 auto
    padding: 10px 0

    .is-editing &
      flex: 1 0 auto
      margin-right: 0

    +bpw('sm')
      padding: 0
      flex: 0 1 auto !important
      margin-right: 1.5em !important

  .btn-agree
    background: $blue
    border-radius: 6px
    color: white !important
    transition: transform .14s ease, background .14s ease
    margin-left: 1.5em
    margin-top: .4em
    margin-right: 0 !important
    font-weight: bold
    flex: 1 0 auto

    .is-editing &
      margin-left: auto

    +bpw('sm')
      flex: 0 0 auto
      padding: 8px 12px
      margin-left: auto

      &:hover
        transform: scale(1.05)
        background: lighten($blue, 10%)

.btn-show-details
  .show-details &
    padding: 8px 12px
    border-radius: 6px

    &:after
      content: "\00a0▼"

.details-text
  flex: 1 1 auto
  background: #f8f8f8
  display: flex
  min-height: 0

.details-text-padding
  padding: $padding

.btn-close
  margin-left: auto
  font-size: 24px
  line-height: 0
  width: 14px
  height: 14px
  position: relative

  &:after
    // larger click area
    content: ""
    position: absolute
    top: -15px
    left: -15px
    bottom: -15px
    right: -15px

.consent-checkbox
  margin-right: .3em

.consent-list
  color: #707070
  margin: 0 1em 0 0
  padding: 0

  > li
    display: inline-block
    margin-right: 1em
</style>

<script>
import {createNamespacedHelpers} from 'vuex';
import pRetry from '../utils/p-retry';
import gsap from 'gsap';
import rgba from 'polished/lib/color/rgba';
import CfScrollbar from '../../../lib/ui/vue/container/cf_scrollbar';
import {i18n} from '../i18n';
import * as Consent from '../api/consent';

const {mapState, mapMutations} = createNamespacedHelpers(
  'inreal/consentManager',
);
export const USER_CHOICE_FALLBACK_KEY =
  'inreal/consentManager.userChoiceFallback';

export default {
  name: 'CfConsentManager',
  components: {
    CfScrollbar,
  },
  mixins: [i18n.mixin],
  props: {
    opensInitially: {
      type: Boolean,
      default: true,
    },
    hasCloseButton: {
      type: Boolean,
      default: false,
    },
    hasDeclineButton: {
      type: Boolean,
      default: false,
    },
    primaryColor: {
      type: String,
      default: '#1278ef',
    },
  },
  data () {
    return {
      editing: false,
      localConsentTypes: null,
      showDetails: false,
    };
  },
  computed: {
    ...mapState(['mode', 'consentTypes']),
    allowSelection () {
      // only allow selection of consent types if any exist
      return (
        this.localConsentTypes != null && this.localConsentTypes.length > 0
      );
    },
    colorStyles () {
      return {color: this.primaryColor};
    },
    secondaryButtonStyles () {
      return {
        ...this.colorStyles,
        backgroundColor: this.showDetails ? rgba(this.primaryColor, 0.1) : null,
      };
    },
  },
  watch: {
    mode () {
      // refresh when opening
      if (this.mode != null) {
        this.refreshConsentOptions();
      }
    },
    consentTypes: {
      immediate: true,
      handler () {
        this.localConsentTypes = this.consentTypes?.map(v => ({
          ...v,
          // for user interface: set unconfirmed (null) to false as default
          status: Boolean(v.status),
        }));
      },
    },
  },
  async mounted () {
    await this.refreshConsentOptions();
    if (this.$_processFallbackChoice()) {
      // we just handled a fallback request in a previous session, no need to prompt again
      return;
    }

    if (this.consentTypes != null) {
      // unconfirmed settings (status == null) means that the user needs to interact
      const shouldOpen = this.consentTypes.findIndex(v => v.status == null) > -1;
      if (this.opensInitially && shouldOpen) {
        this.open({mode: 'minimal'});
      }
    }
  },
  methods: {
    ...mapMutations(['close', 'open', 'setConsentTypes']),
    async refreshConsentOptions () {
      const settings = await Consent.getConsentOptions();
      if (settings == null || settings.length === 0) {
        return;
      }
      this.setConsentTypes(settings);
    },
    enableEditing () {
      this.editing = true;
    },
    $_processFallbackChoice () {
      if (this.consentTypes == null) {
        return false;
      }
      // do we have a fallback choice on record from a previous session?
      const fallbackChoice = window?.localStorage.getItem(
        USER_CHOICE_FALLBACK_KEY,
      );
      if (fallbackChoice != null) {
        this.$_setConsentSettings(this.consentTypes, Boolean(JSON.parse(fallbackChoice)));
        window.localStorage.removeItem(USER_CHOICE_FALLBACK_KEY);
        return true;
      }
      return false;
    },
    $_close () {
      this.showDetails = false;
      this.editing = false;
      this.close();
    },
    $_onCloseClick () {
      try {
        Consent.registerInitialConsent();
      } catch (err) {
        // noop
      }
      this.$_close();
    },
    $_onAgreeSelectionClick () {
      this.$_setConsentSettings(this.localConsentTypes);
    },
    $_onAgreeAllClick () {
      this.$_setConsentSettings(this.consentTypes, true);
    },
    $_onDeclineClick () {
      this.$_setConsentSettings(this.consentTypes, false);
    },
    async $_setConsentSettings (settings, status = null) {
      // no specific settings were recorded
      // if there is a general opt-in or opt-out, save that to localStorage as fallback
      if (settings == null && status != null) {
        this.$_setConsentFallback(status);
        return;
      }
      const finalSettings =
        status != null ? settings.map(v => ({...v, status})) : settings;

      // save to vuex
      this.setConsentTypes(finalSettings);
      if (this.mode === 'full-text') {
        this.editing = false;
      } else {
        this.$_close();
      }

      // in the background: send to piwik api with exponential backoff retries
      await pRetry(() => Consent.setConsentOptions(finalSettings), {
        retries: 5,
      }).catch(err => {
        // after enough retries, set a fallback flag
        if (status != null) {
          this.$_setConsentFallback(status);
        }
        // istanbul ignore next
        if (process.env.NODE_ENV !== 'test') {
          // rethrow for sentry or similar
          // this would crash tests inevitably
          throw err;
        }
      });
    },
    $_setConsentFallback (status) {
      try {
        window?.localStorage.setItem(
          USER_CHOICE_FALLBACK_KEY,
          JSON.stringify(status),
        );
      } catch (err) {
        // noop
      }
      this.$_close();
    },
    $_onDetailsLinkClick () {
      this.showDetails = !this.showDetails;
    },
    $_onEnter (el, done) {
      gsap.fromTo(
        el,
        {yPercent: 100},
        {yPercent: 0, duration: 0.3, ease: 'power2', onComplete: done},
      );
    },
    $_onLeave (el, done) {
      gsap.to(el, {yPercent: 100, duration: 0.3, onComplete: done});
    },
    $_onDetailsBeforeEnter (el) {
      // animate with semi-FLIP technique
      // 1. store position before change
      this._cachedY = this.$refs.root.getBoundingClientRect().y;
    },
    $_onDetailsEnter (el, done) {
      // 2. commit change (vue does this for us)
      // 3. calculate position difference to stored position from (1)
      const deltaY = this._cachedY - this.$refs.root.getBoundingClientRect().y;
      // 4. apply inverted difference, animate to final position
      gsap.from(this.$refs.root, {
        y: deltaY,
        duration: 0.4,
        ease: 'power2',
        onComplete: done,
      });
    },
    $_onDetailsLeave (el, done) {
      // FLIP does not work here, as vue will not remove the element until after leave
      // also, the element needs to still exist during the animation so it can
      // smoothly slide off the screen
      const deltaY = el.getBoundingClientRect().height;
      gsap.to(this.$refs.root, {
        y: deltaY,
        duration: 0.4,
        ease: 'power2',
        onComplete: () => {
          gsap.set(this.$refs.root, {y: 0});
          done();
        },
      });
    },
  },
};
</script>
