import {isArray} from '../../../lib/core/js/array';
import {isObject} from '../../../lib/core/js/object';
import {hasKey} from '../../../lib/core/js/collections';
import {isNumber, isBool, isString} from '../validate';

export function trim (val, chr) {
  val = toString(val);
  let rgxtrim = (!chr) ? new RegExp('^\\s+|\\s+$', 'g') : new RegExp(`^${chr}+|${chr}+$`, 'g');
  return val.replace(rgxtrim, '');
}

export function rtrim (val, chr) {
  val = toString(val);
  let rgxtrim = (!chr) ? new RegExp('\\s+$') : new RegExp(`${chr}+$`);
  return val.replace(rgxtrim, '');
}

export function ltrim (val, chr) {
  val = toString(val);
  let rgxtrim = (!chr) ? new RegExp('^\\s+') : new RegExp(`^${chr}+`);
  return val.replace(rgxtrim, '');
}

/**
 * @param {*} value
 * @returns {boolean}
 */
export function toBool (value) {
  if (isBool(value)) {
    return value;
  }
  if (/^\d+$/.test(value)) {
    value = toFloat(value);
  }
  if (isNumber(value)) {
    return value > 0;
  } else if (isString(value) && value.toUpperCase() === 'TRUE') {
    return true;
  }
  return false;
}
/**
 * @param {boolean} value
 * @returns {number|boolean}
 */
export function boolToNumber (value) {
  if (value === false) {
    return 0;
  } else if (value === true) {
    return 1;
  } else {
    return value;
  }
}

/**
 * @param {string|Object|Array} value
 * @returns {string}
 */
export function toString (value) {
  if (value == null || isArray(value) || isObject(value)) {
    return '';
  }
  return `${value}`;
}

/**
 * @param {string|number} value
 * @returns {int}
 */
export function toInteger (value) {
  return parseInt(value, 10);
}

/**
 * @param {string|number} value
 * @returns {number}
 */
export function toFloat (value) {
  return parseFloat(value);
}

/**
 *
 * @param {number} value, the value to round
 * @param {integer} decimals, decimal points
 * @param {string} mode, [ up | down | none ]
 * @returns {number}
 */
export function round (value, decimals, mode) {
  let a;
  if (decimals == null || decimals < 0) {
    a = 1; // zero decimal points
  } else {
    a = 10 ** decimals;
  }
  if (mode === 'up') {
    return Math.ceil(value * a) / a;
  } else if (mode === 'down') {
    return Math.floor(value * a) / a;
  } else {
    return Math.round(value * a) / a;
  }
}

/**
 * formats a number, optional in user format
 * @param {number} val
 * @param {Integer} decimals, optional, null for decimals like in given val
 * @param {string} decPoint, in de = ',' in en = '.'
 * @param {string} thounsandSep, in de = '.', in en = ','
 * @returns {string}
 */
export function numberToString (val, decimals, decPoint = ',', thounsandSep = '.') {
  if (!isNumber(val)) {
    return val;
  }
  let res = toFloat(val);
  if (isInteger(decimals, 0)) {
    res = res.toFixed(decimals);
  }
  return toString(res)
    .replace('.', decPoint)
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, `$1${thounsandSep}`);
}

/**
 * unformats a number, optional from user format
 * @param {string} val, the number like .12 | 1.23 | 12 | 1,234.56
 * @param {string} decPoint, in de = ',' in en = '.'
 * @returns {number}
 */
export function stringToNumber (val, decPoint = ',') {
  let reg = new RegExp(`[^0-9${regEsc(decPoint)}]`);
  val = toString(val).replace(reg, '').replace(decPoint, '.');
  if (val.indexOf('.') === -1) {
    return toInteger(val);
  } else {
    return toFloat(val);
  }
}

/**
 * will return the node from an object by a given reference as
 * dot-separated path e.g. foo.bar.nodename
 * function get() from https://github.com/sindresorhus/dot-prop
 * (set() and delete() also available in this project)
 * @param {Object} obj
 * @param {string} path
 * @param {bool} returnParent, return the last valid parent path, if path is not found
 * @returns {Object}
 */
export function path (obj, path, returnParent) {
  if (!isObject(obj) || !isString(path)) {
    return null;
  }
  const pathArray = path.split('.');
  for (let i = 0; i < pathArray.length; i++) {
    if (!Object.prototype.propertyIsEnumerable.call(obj, pathArray[i])) {
      return returnParent ? obj : null;
    }
    let child = obj[pathArray[i]];
    if (child == null) {
      if (i !== pathArray.length - 1) {
        return returnParent ? obj : null;
      }
      break;
    }
    obj = child;
  }
  return obj;
}

/*
 | For HTML-Fields with one p-node
 */
/**
 * @param {string|Object|Array} value
 * @param {boolean} br2space
 * @returns {string}
 */
export function htmlToText (value, br2space) {
  if (isArray(value) && hasKey(value, 0) && hasKey(value[0], 'html')) {
    value = value[0].html;
  }
  value = toString(value);
  if (br2space) {
    value = value.replace(/<br\s?\/>/gi, ' ');
  }
  return value;
}

/**
 * @param {string} unsafe
 * @param {boolean} doRemoveNewlines
 * @returns {string}
 */
export function escape (unsafe, doRemoveNewlines) {
  if (!unsafe) {
    return unsafe;
  }
  if (doRemoveNewlines) {
    unsafe = removeNewlines(unsafe);
  }
  let map = {
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;',
    '"': '&quot;',
    '\'': '&#039;',
  };
  return unsafe.replace(/[&<>"']/g, m => {
    return map[m];
  });
}

/**
 * @param {string} str
 * @returns {string}
 */
export function removeNewlines (str) {
  return str.replace(/\s+/gm, ' ').trim();
}

/**
 * fooBar
 * @returns {string}
 */
export function camelCase (...args) {
  let str = args.join(' ');
  let res = words(str, 'TO_LOWER_CASE');
  if (res.length > 0) {
    return res[0] + res.slice(1).map(val => {
      val = String(val);
      return val.charAt(0).toUpperCase() + val.slice(1);
    }).join('');
  }
  return '';
}

/**
 * FooBar
 * @returns {string}
 */
export function pascalCase (...args) {
  let str = args.join(' ');
  let res = words(str, 'TO_LOWER_CASE');
  if (res.length > 0) {
    return res.map(val => {
      val = String(val);
      return val.charAt(0).toUpperCase() + val.slice(1);
    }).join('');
  }
  return '';
}

/**
 * foo-bar
 * @returns {string}
 */
export function kebabCase (...args) {
  let str = args.join(' ');
  let res = words(str, 'TO_LOWER_CASE');
  return res.join('-');
}

/**
 * foo_bar
 * @returns {string}
 */
export function snakeCase (...args) {
  let str = args.join(' ');
  let res = words(str, 'TO_LOWER_CASE');
  return res.join('_');
}

/**
 * foo_bar
 * @param {string|Object|Array} str
 * @param {string} convert
 * @returns {string}
 */
export function words (str, convert) {
  str = toString(str);
  str = str.replace(/([A-Z])/g, ' $1');
  if (convert === 'TO_UPPER_CASE') {
    str = str.toUpperCase();
  }
  if (convert === 'TO_LOWER_CASE') {
    str = str.toLowerCase();
  }
  return str.match(/\b(\w+)\b/g);
}


/**
 * Creates an uri with leading slash from any kind of given parameters
 * @param {mixed} args, string or array with strings
 * @returns {string}
 */
export function toUri (...args) {
  let res = [], parts = [];
  args.forEach(arg => {
    if (isArray(arg)) {
      parts = parts.concat(arg);
    } else {
      parts.push(arg);
    }
  });
  parts.forEach(part => {
    part = toString(part);
    if (part) {
      res.push(trim(part, '/'));
    }
  });
  return encodeURI(`/${res.join('/')}`);
}

/**
 * Creates an url with leading slash from any kind of given parameters
 * where first arg is host (http://domain.de)
 * @param {mixed} args, string or array with strings
 * @returns {string}
 */
export function toUrl (...args) {
  let res = rtrim(args.shift());
  if (args.length) {
    res += toUri(...args);
  }
  return res;
}

/**
 * normalize route path like /foo/bar#hash
 * @param {*} mixed, $route or string
 * @returns {string}
 */
export function getRoutePath (mixed) {
  if (isObject(mixed)) {
    return toUri(mixed.fullPath.split('/'));
  } else if (isString(mixed)) {
    return toUri(mixed.split('/'));
  }
}

/**
 * @param {Object} obj
 * @returns {string}
 */
export function toParams (obj) {
  let res = [];
  Object.keys(obj).forEach(key => {
    const value = obj(key);
    res.push(`${key}=${encodeURIComponent(value)}`);
  });
  return res.join('&');
}

// escape all special characters for regex
export function regEsc (str) {
  return toString(str).replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

export function timeStringToTimestamp (value, user) {
  if (!isString(value)) {
    return;
  }

  let times = value.split(':');
  let hours = toInteger(times[0]);
  let minutes = toInteger(times[1]) || 0;
  let seconds = toInteger(times[2]) || 0;
  let res = new Date(1970, 1, 1, hours, minutes, seconds);

  if (
    res.getHours() === hours &&
    res.getMinutes() === minutes &&
    res.getSeconds() === seconds) {
    return res;
  }
}

export function dateStringToTimestamp (value, user) {
  if (!isString(value)) {
    return null;
  }

  let dates = value.split('-');
  let day = toInteger(dates[2]);
  let month = toInteger(dates[1]) - 1;
  let year = toInteger(dates[0]);
  let res = new Date(year, month, day);

  if (
    res.getDate() === day &&
    res.getMonth() === month &&
    res.getFullYear() === year) {
    return res;
  }
  return null;
}
