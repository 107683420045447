import {EmptyClass} from '../classes';
import {disposable} from '../gc/disposable';
import {lastElement} from '../array';

/**
 * Converts a css selector into a tagged css selector, honoring pseudo classes and pseudo elements.
 *
 * Examples with tag '[0]'
 *
 * Input: button::hover
 * Output: button[0]::hover
 *
 * Input: button.btn::hover
 * Output: button.btn[0]::hover
 *
 * Input: ::hover
 * Output: [0]::hover
 *
 * Input: .test
 * Output: .test[0]
 *
 * @param {string} selector
 * @param {string} tag
 * @returns {string}
 */
function tagSelector (selector, tag) {
  const index = selector.indexOf(':');
  let result = '';

  // If there's a ':' or '::' apply patch
  if (index !== -1) {
    // If it's at position 0 it's a selector starting with ':' or '::'.
    // In this case prepend the tag.
    if (index === 0) {
      result = `${tag}${selector}`;
    } else {
      // Otherwise insert before first occurance of ':' or '::'.
      result = `${selector.slice(0, index)}${tag}${selector.slice(index)}`;
    }
  } else {
    // Otherwise append.
    result = `${selector}${tag}`;
  }

  return result;
}

/**
 * This class can be used in vue components to apply dynamic css style rules which can be updated after reactive changes.
 */
export class DynamicStyle extends disposable(EmptyClass) {
  /**
   * @param {*} vueComponentInstance
   * @param {Array<{
   *   key: (string|number),
   *   selector: (string|Array<string>),
   *   properties: ?Object,
   *   important: boolean,
   *   scoped: boolean,
   *   root: boolean,
   * }>} rules
   */
  constructor (vueComponentInstance, rules = []) {
    super();

    /**
     * @private
     */
    this._component = vueComponentInstance;

    /**
     * @type {HTMLStyleElement|null}
     * @private
     */
    this._styleElement = null;

    /**
     * @type {Map<(string|null), {
     *   rule: CSSStyleRule,
     *   important: boolean,
     * }>}
     * @private
     */
    this._rules = new Map();

    this._component.$el.setAttribute(this.idTag, '');

    rules.forEach(element => {
      const root = element.root ?? false;
      const scoped = element.scoped ?? true;
      const important = element.important ?? false;

      let attributeTag = `[${this.idTag}]`;

      // If the input is an array of selectors join them with a comma.
      const selector = Array.isArray(element.selector)
        ? element.selector.join(', ')
        : element.selector;

      const resultSelector = selector
        .split(',')
        .map(part => part.trim())
        .map(singleSelector => {
          // Get the parts of a single selector
          const selectorParts = singleSelector
            .split(' ')
            .map(part => part.trim());

          // Apply tag with component uid
          if (root === false) {
            selectorParts.unshift(attributeTag);
          } else {
            let firstSelectorPart = selectorParts[0];
            if (firstSelectorPart != null) {
              selectorParts[0] = tagSelector(firstSelectorPart, attributeTag);
            }
          }

          // Apply tag with component scope id
          if (scoped) {
            const lastSelectorPart = lastElement(selectorParts);
            if (lastSelectorPart != null) {
              selectorParts[selectorParts.length - 1] = tagSelector(lastSelectorPart, `[${this._component.$options._scopeId}]`);
            }
          }

          // Return joined parts
          return selectorParts
            .filter(x => x !== '')
            .join(' ');
        })
        .join(', ');

      this._rules.set(
        element.key,
        {
          rule: this._createRule(resultSelector),
          important,
        },
      );

      this.setProperties(element.key, element.properties);
    });
  }

  /**
   * @returns {HTMLStyleElement}
   * @private
   */
  _createStyleElement () {
    const found = document.querySelector(`style[data-id-tag="${this.idTag}"]`);
    if (found != null) {
      return found;
    }

    const element = document.createElement('style');
    element.type = 'text/css';
    element.setAttribute('data-id-tag', this.idTag);
    document.head.appendChild(element);

    return element;
  }

  /**
   * @returns {string}
   */
  get idTag () {
    return `dynamic-style-${this._component._uid}`;
  }

  /**
   * Get's the style element for this instance.
   *
   * @returns {HTMLStyleElement}
   */
  get styleElement () {
    if (this._styleElement == null) {
      this._styleElement = this._createStyleElement();
    }

    return this._styleElement;
  }

  /**
   * @param {string} selector
   * @returns {CSSStyleRule}
   * @private
   */
  _createRule (selector) {
    /**
     * @type {CSSStyleSheet}
     */
    const sheet = this.styleElement.sheet;
    const index = sheet.cssRules.length;

    sheet.insertRule(`${selector} { }`, index);

    return sheet.cssRules[index];
  }

  /**
   * @param {string|number} key
   * @param {?Object} properties
   * @deprecated Use [setProperties] instead.
   */
  update (key, properties) {
    this.setProperties(key, properties);
  }

  /**
   * Set's all properties for a rule.
   *
   * All previous properties will be removed.
   *
   * @param {string|number} key
   * @param {?Object} properties
   */
  setProperties (key, properties) {
    const ruleConfig = this._rules.get(key);
    if (ruleConfig != null) {
      // If properties is null, set empty rule
      if (properties == null) {
        ruleConfig.rule.style.cssText = '';
        return;
      }

      const cssText = Object.entries(properties)
        .map(([key, value]) => {
          if (value == null) {
            return '';
          }

          let cssValue = value;
          if (ruleConfig.important) {
            cssValue += ' !important';
          }

          return `${key}: ${cssValue};`;
        })
        .join(' ')
        .trim();

      ruleConfig.rule.style.cssText = cssText;
    }
  }

  disposeInternal () {
    this._component.$el.removeAttribute(this.idTag);
    this._rules.clear();
    this.styleElement.parentElement.removeChild(this.styleElement);
    super.disposeInternal();
  }
}
