<template lang="pug">
  .cf-scrollbar(:class="classes")
    .simplebar-wrapper
      .simplebar-height-auto-observer-wrapper
        .simplebar-height-auto-observer
      .simplebar-mask
        .simplebar-offset
          .simplebar-content-wrapper(ref="scrollElement")
            .simplebar-content(ref="contentElement")
              slot
      .simplebar-placeholder
    .simplebar-track.simplebar-horizontal
      .simplebar-scrollbar
    .simplebar-track.simplebar-vertical
      .simplebar-scrollbar
    template(v-if="showYStartIndicator")
      cf-scroll-indicator.indicator.y-start(direction="down" :class="{'visible': yStartVisible}")
    template(v-if="showYEndIndicator")
      cf-scroll-indicator.indicator.y-end(direction="up" :class="{'visible': yEndVisible}")
    template(v-if="showXStartIndicator")
      cf-scroll-indicator.indicator.x-start(direction="right" :class="{'visible': xStartVisible}")
    template(v-if="showXEndIndicator")
      cf-scroll-indicator.indicator.x-end(direction="left" :class="{'visible': xEndVisible}")
</template>

<style lang="sass" scoped>
@use '../../sass/variables' as *
@import '~simplebar/dist/simplebar.min.css'

.cf-scrollbar
  position: relative

.simplebar-vertical
  width: 16px

  .simplebar-scrollbar
    &::before
      left: 10px
      right: 2px
      transition: left .1s ease-out, opacity .25s ease-out

      .hide-rails &
        opacity: 0 !important

  &.simplebar-hover
    .simplebar-scrollbar::before
      left: 2px

.simplebar-horizontal
  height: 16px

  .simplebar-scrollbar
    top: 0
    bottom: 0
    min-height: auto
    height: auto

    &::before
      height: auto
      top: 10px
      bottom: 2px
      transition: top .1s ease-out, opacity .25s ease-out

      .hide-rails &
        opacity: 0 !important

  &.simplebar-hover
    .simplebar-scrollbar::before
      top: 2px

.indicator
  position: absolute
  opacity: 0
  transition: opacity $animation-duration-medium ease-out

  &.y-start
    top: 0
    left: 0
    right: 0

  &.y-end
    bottom: 0
    left: 0
    right: 0

  &.x-start
    left: 0
    top: 0
    bottom: 0

  &.x-end
    right: 0
    top: 0
    bottom: 0

  &.visible
    opacity: 1
</style>

<script>
import {throttle} from '../../../core/js/animation/rendering';
import {DynamicStyle} from '../../../core/js/css/dynamic_style';
import {listen} from '../../../core/js/events';

import SimpleBar from 'simplebar';
import CfScrollIndicator from './cf_scroll_indicator';

/**
 * A scroll container using the simplebar library.
 *
 * Events:
 * - scroll
 * - y-reach-end
 * - y-reach-start
 * - x-reach-end
 * - x-reach-start
 */
export default {
  name: 'CfScrollbar',
  components: {
    CfScrollIndicator,
  },
  props: {
    /**
     * If the rails should be hidden.
     */
    hideRails: {
      type: Boolean,
      default: false,
    },
    /**
     * If the rails should be hidden automatically.
     */
    autoHide: {
      type: Boolean,
      default: false,
    },
    /**
     * The color of the bar.
     */
    color: {
      type: String,
      default: 'black',
    },
    scrollbarMinSize: {
      type: Number,
      default: 30,
    },
    scrollbarMaxSize: {
      type: Number,
      default: null,
    },
    /**
     * If the y start indicator should be active
     */
    showYStartIndicator: {
      type: Boolean,
      default: true,
    },
    /**
     * If the y end indicator should be active
     */
    showYEndIndicator: {
      type: Boolean,
      default: false,
    },
    /**
     * If the x start indicator should be active
     */
    showXStartIndicator: {
      type: Boolean,
      default: true,
    },
    /**
     * If the x end indicator should be active
     */
    showXEndIndicator: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      scroller: null,
      yStartVisible: false,
      yEndVisible: false,
      xStartVisible: false,
      xEndVisible: false,
      listeners: [],
      dynamicStyle: null,
    };
  },
  computed: {
    classes () {
      return {
        'hide-rails': this.hideRails,
      };
    },
    scrollElement () {
      return this.$refs.scrollElement;
    },
    contentElement () {
      return this.$refs.contentElement;
    },
    yIsOverflowing () {
      return this.scroller?.axis.y.isOverflowing;
    },
    xIsOverflowing () {
      return this.scroller?.axis.x.isOverflowing;
    },
  },
  watch: {
    color (newValue) {
      this.dynamicStyle?.setProperties('color', {
        'background-color': newValue,
      });
    },
    yIsOverflowing () {
      this.$_calculateIndicators();
    },
    xIsOverflowing () {
      this.$_calculateIndicators();
    },
  },
  mounted () {
    this.$_create();
    this.dynamicStyle = new DynamicStyle(this, [
      {
        key: 'color',
        selector: '.simplebar-scrollbar::before',
        properties: {
          'background-color': this.color,
        },
      },
    ]);
  },
  beforeDestroy () {
    this.dynamicStyle?.dispose();
    this.$_destroy();
  },
  activated () {
    this.$_create();
  },
  deactivated () {
    this.$_destroy();
  },
  updated () {
    this.update();
  },
  methods: {
    update () {
      this.scroller?.recalculate();
    },
    $_calculateIndicators (emitEvents = false) {
      const wasYStartVisible = this.yStartVisible;
      const wasYEndVisible = this.yEndVisible;
      const wasXStartVisible = this.xStartVisible;
      const wasXEndVisible = this.xEndVisible;

      this.yStartVisible = false;
      this.yEndVisible = false;
      this.xStartVisible = false;
      this.xEndVisible = false;

      let yReachEnd = false;
      let yReachStart = false;
      let xReachEnd = false;
      let xReachStart = false;

      if (this.scroller != null) {
        const end = this.scroller.contentWrapperEl.offsetHeight
          + this.scroller.contentWrapperEl.scrollTop;
        this.yStartVisible = this.scroller.contentWrapperEl.scrollTop > 0;
        this.yEndVisible = end < this.scroller.contentEl.scrollHeight;

        yReachStart = !this.yStartVisible && wasYStartVisible;
        yReachEnd = !this.yEndVisible && wasYEndVisible;
      }

      if (this.scroller != null) {
        const end = this.scroller.contentWrapperEl.offsetWidth
          + this.scroller.contentWrapperEl.scrollLeft;
        this.xStartVisible = this.scroller.contentWrapperEl.scrollLeft > 0;
        this.xEndVisible = end < this.scroller.contentEl.scrollWidth;

        xReachStart = !this.xStartVisible && wasXStartVisible;
        xReachEnd = !this.xEndVisible && wasXEndVisible;
      }

      if (emitEvents) {
        if (yReachEnd) {
          this.$emit('y-reach-end');
        }
        if (yReachStart) {
          this.$emit('y-reach-start');
        }
        if (xReachEnd) {
          this.$emit('x-reach-end');
        }
        if (xReachStart) {
          this.$emit('x-reach-start');
        }
      }
    },
    async $_create () {
      if (this.scroller == null) {
        const options = {
          autoHide: this.autoHide,
        };

        if (this.scrollbarMinSize != null) {
          options.scrollbarMinSize = this.scrollbarMinSize;
        }

        if (this.scrollbarMaxSize != null) {
          options.scrollbarMaxSize = this.scrollbarMaxSize;
        }

        this.scroller = new SimpleBar(this.$el, options);
        this.listeners.push(listen(this.scroller.getScrollElement(), 'scroll', throttle(() => {
          this.$emit('scroll');
          this.$_calculateIndicators(true);
        })));
        await this.$nextTick();
        this.$_calculateIndicators();
      }
    },
    $_destroy () {
      if (this.scroller != null) {
        this.listeners.forEach(fn => fn());
        this.scroller.unMount();
      }
    },
  },
};
</script>
