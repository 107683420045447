const state = () => ({
  open: false,
});

const getters = {
  getOpen: (state, getters, rootState, rootGetters) => {
    return state.open;
  },
};

const mutations = {
  setOpen (state, payload) {
    state.open = payload;
  },
  toggleOpen (state) {
    state.open = !state.open;
  },
};

const actions = {};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
