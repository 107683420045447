<template lang="pug">
  article
    .inner
      .swiper-container(ref="swiper")
        .swiper-wrapper
          template(v-for="file in getFiles")
            .swiper-slide
              cw-picture(:file="file" :size="'sections.slider'" :is-fullsize="true")
        .swiper-pagination(ref="pagination")
        .swiper-button-next(ref="next")
          include ../../assets/svg/arrow-small.svg
        .swiper-button-prev(ref="prev")
          include ../../assets/svg/arrow-small.svg
</template>

<style lang="sass" scoped>
@import '../../styles/variables'
@import '../../lib/core/sass/bp'

article
  position: relative
  display: block
  margin: 0
  width: 100%
  height: 70vH
  +bpw(lg)
    height: 100vH
  background-color: transparent
  overflow: auto
  box-sizing: border-box

.inner
  position: absolute
  border-radius: 8px
  left: 8px
  top: 8px
  bottom: 8px
  right: 8px
  overflow: hidden
  background-color: white

</style>

<script>
import Swiper from 'swiper/js/swiper.min.js';
import CwPicture from '../elements/cw_picture';
import {trackSlider} from '@/tracking';

export default {
  name: 'CwModalGallery',
  components: {
    CwPicture,
  },
  props: {
    modal: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      slider: null,
    };
  },
  computed: {
    getFiles () {
      return this.modal.content.elements;
    },
  },
  watch: {},
  mounted () {
    this.slider = new Swiper(this.$refs['swiper'], {
      tracking: this.modal.tracking.title,
      preloadImages: false,
      pagination: {
        el: this.$refs['pagination'],
        clickable: true,
      },
      speed: 800,
      effect: 'fade',
      navigation: {
        nextEl: this.$refs['next'],
        prevEl: this.$refs['prev'],
      },
      on: {
        slideChange () {
          trackSlider(`${this.params.tracking}$$${this.activeIndex}`);
        },
      },
    });
  },
  beforeDestroy () {},
  methods: {},
};
</script>
