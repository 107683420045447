import {pascalCase, getRoutePath, rtrim, toInteger} from '../utils/convert';
import {isString} from '../utils/validate';
import {isObject} from '../../lib/core/js/object';

class DOMPlugin {

  /*
  |--------------------------------------------------------------------------
  | Register
  |--------------------------------------------------------------------------
  */

  install (Vue, options) {
    this.Vue = Vue;
    this.options = options;
    Vue.prototype.$dom = {
      getActiveClasses: (route, uri, activeClass, exactClass) => {
        return this.getActiveClasses(route, uri, activeClass, exactClass);
      },
      getElement: mixed => {
        return this.getElement(mixed);
      },
      isElement: node => {
        return this.isElement(node);
      },
      getDocumentHeight: () => {
        return this.getDocumentHeight();
      },
      getDocumentWidth: () => {
        return this.getDocumentWidth();
      },
      getDimensions: mixed => {
        return this.getDimensions(mixed);
      },
      getWidth: mixed => {
        return this.getWidth(mixed);
      },
      getHeight: mixed => {
        return this.getHeight(mixed);
      },
      getScrollPos: mixed => {
        return this.getScrollPos(mixed);
      },
      isScrolledIntoView: (mixed, context) => {
        return this.isScrolledIntoView(mixed, context);
      },
      setStyle: (mixed, style, value) => {
        return this.setStyle(mixed, style, value);
      },
      getBreakpoint: () => {
        return this.getBreakpoint();
      },
      fixBody: () => {
        return this.fixBody();
      },
      releaseBody: () => {
        return this.releaseBody();
      },
    };
    if (options.loadGlobalElements) {
      let baseElements = require.context('@/components', true, /^\.\/(base)\/.*\.(vue|js)$/);
      baseElements.keys().forEach(path => {
        let component = baseElements(path);
        component = (component.default || v);
        Vue.component(pascalCase(component.name), component);
      });
    }
  }

  /*
  |--------------------------------------------------------------------------
  | Interface
  |--------------------------------------------------------------------------
  */
  // eslint-disable-next-line valid-jsdoc
  /**
   * @param {mixed} route1, either $route, { path: foo, hash: bar} or string
   * @param {mixed} route2, either $route, { path: foo, hash: bar} or string
   * @param {string} activeClass, the active class to return
   * @param {string} exactClass, the exact active class to return
   * @returns string
   */
  getActiveClasses (route1, route2, activeClass, exactClass) {
    route1 = getRoutePath(route1);
    route2 = getRoutePath(route2);
    let res = [];
    if (route1 === route2) {
      res.push(activeClass || 'is-active');
      res.push(exactClass || 'is-exact-active');
    } else if (route1.substr(0, route2.length + 1) === `${rtrim(route2, '/')}/`) {
      res.push(activeClass || 'is-active');
    }
    return res.concat(' ');
  }

  // eslint-disable-next-line valid-jsdoc
  /**
   * mixed can be a string = element-ID or a Vue.$refs
   * If ref="foo" is used on root element or on a component, the reference
   * will point to the vue component. Otherwise it will point to the DOM
   * element. Here we translate to DOM element.
   * @param {mixed} mixed, string, Vue instance, DOM element
   */
  getElement (mixed) {
    if (mixed instanceof HTMLElement) {
      return mixed;
    } else if (isString(mixed)) {
      return document.getElementById(mixed);
    } else if (mixed === window) {
      return window;
    } else if (mixed instanceof this.Vue) {
      return mixed.$el;
    }
    return null;
  }

  /**
   * @param {HTMLElement} node
   * @returns {boolean}
   */
  isElement (node) {
    return node && node instanceof HTMLElement;
  }

  /*
   | inclucing invisible part
   */
  getDocumentHeight () {
    return Math.max(
      document.documentElement['clientHeight'],
      document.body['scrollHeight'],
      document.documentElement['scrollHeight'],
      document.body['offsetHeight'],
      document.documentElement['offsetHeight'],
    );
  }

  /*
   | inclucing invisible part
   */
  getDocumentWidth () {
    return Math.max(
      document.documentElement['clientWidth'],
      document.body['scrollWidth'],
      document.documentElement['scrollWidth'],
      document.body['offsetWidth'],
      document.documentElement['offsetWidth'],
    );
  }

  /**
   * get dimensions of a dom element
   * @param {mixed} mixed, @see getElement()
   * @returns {Object}
   */
  getDimensions (mixed) {
    let node = this.getElement(mixed);
    let res = {};
    if (this.isElement(node)) {
      res.width = this.getWidth(node);
      res.height = this.getHeight(node);
      res.top = node.offsetTop;
      res.left = node.offsetLeft;
      res.bottom = res.top + res.height;
      res.right = res.left + res.width;
    }
    return res;
  }

  /**
   * gets visible width of element or window
   * @param {mixed} mixed, @see getElement()
   * @returns {number}
   */
  getWidth (mixed) {
    let node = this.getElement(mixed);
    if (this.isElement(node)) {
      return toInteger(getComputedStyle(node).width.split('px')[0]); // node.offsetWidth
    } else {
      return window.innerWidth;
    }
  }

  /**
   * gets visible height of element or window
   * @param {mixed} mixed, @see getElement()
   * @returns {number}
   */
  getHeight (mixed) {
    let node = this.getElement(mixed);
    if (this.isElement(node)) {
      return toInteger(getComputedStyle(node).height.split('px')[0]); // node.offsetHeight
    } else {
      return window.innerHeight;
    }
  }

  getScrollPos (mixed, oldTop) {
    let node = this.getElement(mixed);
    let top = this.isElement(node) ? node.scrollTop : Math.abs(toInteger(document.body.style.top || '0')); // window.scrollY
    let height = this.getHeight(node);
    let res = {
      top,
      bottom: top + height,
      scrolled: top > 0,
      direction: null,
    };
    if (oldTop) {
      if (res.top > oldTop) {
        res.direction = 'down';
      } else if (res.top < oldTop) {
        res.direction = 'up';
      }
    }
    return res;
  }

  /**
   * @param {mixed} el, element
   * @param {mixed} context, optional, window on default
   * @returns {boolean}
   */
  isScrolledIntoView (el, context) {
    let node = this.getElement(el);
    let res = {
      visible: false,
      view: false,
      top: false,
      bottom: false,
    };
    if (this.isElement(node)) {
      let top = toInteger(node.getBoundingClientRect().top);
      let bottom = toInteger(node.getBoundingClientRect().bottom);
      let height = this.getHeight(context);

      // element begins to become visible on bottom of the context
      // or bottom begins to become visible on top of the context (= something is visible)
      res.visible = (top < height) && (bottom > 0);

      // the COMPLETE element is visible in context (top and bottom) or,
      // if element is bigger than context, when it fills the complete viewport
      res.view = ((top >= 0) && (bottom <= height)) || ((top < 0) && (bottom > height));

      // top of element is scrolled to top of the context and
      // bottom is not scrolled higher than top of context
      res.top = (top <= 0) && (bottom > 0);

      // bottom of element is scrolled to bottom of the context and
      // top is not scrolled lower than bottom of context
      res.bottom = (bottom <= height) && (top < height);
    }
    return res;
  }


  /**
   * @param {mixed} mixed, @see getElement()
   * @param {mixed} style, empty, property or object
   * @param {string} value, only if style is property
   */
  setStyle (mixed, style, value) {
    let node = this.getElement(mixed);
    if (this.isElement(node)) {
      if (!style) {
        node.style = '';
      } else if (isString(style)) {
        node.style[style] = value;
      } else if (isObject(style)) {
        Object.keys(style).forEach(prop => {
          node.style[prop] = style[prop];
        });
      }
    }
  }

  getBreakpoint () {
    let res;
    Object.keys(this.options.breakpoints).forEach(key => {
      const value = this.options.breakpoints[key];
      if (window.innerWidth <= value) {
        res = key;
      }
    });
    return res;
  }

  fixBody () {
    let scrollPos = this.getScrollPos();
    this.setStyle(document.body, {
      position: 'fixed',
      width: '100%',
      top: `-${scrollPos.top}px`,
    });
  }

  releaseBody () {
    // let scrollPos = 0; // this.getScrollPos(scroll.context);
    this.setStyle(document.body, '');
    /* scroll.jump(
      scrollPos.top, {
        context: scroll.context,
      },
    ); */
  }
}

export default new DOMPlugin();
