<template lang="pug">
  .cw-modal
    .background(v-on:click="$_closeModal")
    component(:is="$_modalTemplate" :key="$_modalKey" :modal="getModalData")
    .close
      button(@click="$_closeModal")
        include ../../assets/svg/close-small.svg
</template>

<style lang="sass" scoped>
@import '../../styles/variables'
@import '../../lib/core/sass/bp'

.cw-modal
  position: fixed
  top: 0
  left: 0
  bottom: 0
  right: 0
  z-index: 1000
.background
  position: absolute
  top: 0
  left: 0
  bottom: 0
  right: 0
  background-color: rgba($color-grey-dark, 0.95)
  cursor: pointer
.close
  position: absolute
  top: 16px
  right: 16px
  z-index: 1000
  button
    cursor: pointer
    background-color: $color-red
    width: 22px
    height: 22px
    padding: 3px
    border-radius: 3px
    transition: all 0.75s
    display: flex
    top: 50%
    fill: white
    &:after
      content: ''
    &:hover
      background-color: white
      svg
        path, polygon
          fill: $color-red
    svg
      position: absolute
      left: 3px
      top: 3px
      width: 16px
      height: 16px
      fill: white
      +bpw(lg)
        left: 5px
        top: 5px
    +bpw(lg)
      width: 26px
      height: 26px

</style>

<script>
import {mapGetters} from 'vuex';

/**
 * load all components from subfolder modals/
 * names of components: 'cw-' + blueprint-name
 */

import Vue from 'vue';
import {kebabCase} from '../../api/utils/convert';

let segments = require.context('@/components/modals', true, /^\.\/.*\.(vue)$/);
segments.keys().forEach(path => {
  let component = segments(path);
  component = (component.default || component);
  Vue.component(kebabCase(component.name), component);
});


export default {
  name: 'CwModal',
  components: {
  },
  props: {},
  data () {
    return {};
  },
  computed: {
    ...mapGetters('inreal/kirbyApi', [
      'getModalData',
    ]),
    $_modalTemplate () {
      return `cw-${this.getModalData.head.blueprint.split('__').join('-')}`;
    },
    $_modalKey () {
      return `cw-modal-${this.getModalData.head.slug}`;
    },
  },
  watch: {},
  mounted () {
  },
  beforeDestroy () {
  },
  methods: {
    $_closeModal () {
      this.$store.commit('inreal/kirbyApi/unsetModalData');
    },
  },
};
</script>
