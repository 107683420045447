<template lang="pug">
  .cw-logo(@click="$_goToHome")
    include ../../assets/svg/logo-head.svg
</template>

<style lang="sass" scoped>
@import '../../lib/core/sass/bp'

.cw-logo
  cursor: pointer
  svg
    position: relative
    width: 100%
    height: 100%

</style>

<script>
import {mapGetters} from 'vuex';

export default {
  name: 'CwLogo',
  components: {},
  props: {},
  data () {
    return {};
  },
  computed: {
    ...mapGetters('inreal/kirbyApi', [
      'getLangKey',
    ]),
  },
  watch: {},
  mounted () {
  },
  beforeDestroy () {
  },
  methods: {
    $_goToHome () {
      this.$router.push({path: `/${this.getLangKey}/`});
    },
  },
};
</script>
