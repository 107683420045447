/**
 * Returns a Promise that resolves after a specified number of browser repaints.
 *
 * @param {number=} frames The frames to wait for
 * @returns {Promise}
 */
export function waitForFrames (frames = 1) {
  return new Promise(resolve => {
    let frameCount = 0;

    function onTick () {
      if (++frameCount === frames) {
        resolve();
      } else {
        window.requestAnimationFrame(onTick);
      }
    }

    window.requestAnimationFrame(onTick);
  });
}

/**
 * Throttles a function to be only called on repaint.
 *
 * Arguments are passed down.
 *
 * @param {Function} func
 * @returns {Function}
 */
export function throttle (func) {
  let isRunning = false;
  return function (...args) {
    if (isRunning) {
      return;
    }
    const context = this;
    isRunning = true;
    window.requestAnimationFrame(() => {
      func.apply(context, args);
      isRunning = false;
    });
  };
}
