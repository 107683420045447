import api from '../index';
import {hasKey} from '../../lib/core/js/collections';
import {isObject} from '../../lib/core/js/object';
import {isArray} from '../../lib/core/js/array';
import {isString, isInteger} from '../utils/validate';
import {round, path, toBool} from '../utils/convert';

class ImagePlugin {
  constructor () {
    this.croptypes = [
      'top-left',
      'top',
      'top-right',
      'left',
      'right',
      'bottom-left',
      'bottom',
      'bottom-right',
    ];
    this.quality = {
      'min': 50,
      'max': 95,
      'default': 90,
    };
    this.viewBox = {};
  }

  /*
  |--------------------------------------------------------------------------
  | Register
  |--------------------------------------------------------------------------
  */

  install (Vue, options) {
    this.Vue = Vue;
    this.options = options;
    Vue.prototype.$image = {
      get: (image, config, hiRes) => {
        return this.get(image, config, hiRes);
      },
      preload: (image, config, hiRes) => {
        return this.preload(image, config, hiRes);
      },
      loadSprite: (url, nodeId) => {
        return this.loadSprite(url, nodeId);
      },
      getViewBox: name => {
        return this.getViewBox(name);
      },
    };
  }

  /*
  |--------------------------------------------------------------------------
  | Interface
  |--------------------------------------------------------------------------
  */

  /**
   * Get image url with all image manipulations coded in string
   * like it is processed by api
   *
   * @param {Object} image, object like given from api (value-node)
   * @param {array} config, format below, can also be path so config is
   *    taken from images.json (like "sections.banner.md")
   * @param {mixed} hiRes, true for double resolution, false for "normal"
   *    resolution, 'auto' for screen detection
   * @returns {Object}
   *
   * config: [
   *   0 {integer || breakpoint || 'screen' || null} max width,
   *   1 {integer || breakpoint || 'screen' || null} max height,
   *   2 {boolean || string} crop, false = no cropping, true = 'center'
   *      or one of these values:
   *      top-left, top, top-right, left, right, bottom-left, bottom, bottom-right
   *   3 {integer} quality, between 50 and 100, default 90
   *   4 {integer || false} blur-factor, default false
   *   5 {boolean} convert to black and white, default false
   * ]
   */
  get (image, config, hiRes) {
    let src, crop, cropType, quality, blur, bw, res;

    // get config-array from presets
    if (isString(config)) {
      config = path(this.options.images, config, true); // true = return parent
      if (!config) {
        config = path(this.options.images, 'default');
      }
    }
    if (!isObject(image) || !isArray(config)) {
      return;
    }
    hiRes = (hiRes === 'auto') ? this._isHiRes() : toBool(hiRes);

    // get width and height
    // width and height can be set to breakpoint-name, which means, the image
    // has the maxiumum dimensions for this breakpoint
    if (isString(config[0]) && hasKey(this.options.breakpoints, config[0])) {
      config[0] = this.options.breakpoints[config[0]];
    }
    if (isString(config[1]) && hasKey(this.options.breakpoints, config[1])) {
      config[1] = this.options.breakpoints[config[1]];
    }

    // get crop option
    // Cropping itselfis always true in api! An uncropped images is generated by giving
    // dimensions that have the same object ratio as the original. Here we compute
    // crop:bool for the dimenension-function and
    // croptype for any special crop
    crop = false;
    cropType = null;
    if (hasKey(config, 2)) {
      if (this.croptypes.indexOf(config[2]) !== -1) {
        crop = true;
        cropType = config[2];
      } else if (config[2] === true) {
        crop = true;
      }
    }

    // get quality option
    quality = false;
    if (
      hasKey(config, 3) &&
      isInteger(config[3]) &&
      config[3] >= this.quality.min &&
      config[3] <= this.quality.max) {
      quality = config[3];
    } else {
      quality = this.quality.default;
    }

    // get blur option
    blur = false;
    if (hasKey(config, 4) && isInteger(config[4]) && config[4] > 0) {
      blur = config[4];
    }

    // get black and white option
    bw = false;
    if (hasKey(config, 5) && config[5] === true) {
      bw = true;
    }

    // calculate dimensions
    res = this._calculateDimensions({width: image.width, height: image.height}, {width: config[0], height: config[1]}, crop, hiRes);

    // normalize extension like kirby does in
    // kirby/src/CMS/Filename.php::sanitizeExtension()
    let extension = image.extension.toLowerCase().replace(/jpeg/, 'jpg');

    // build src
    // filename-(width)x(height)[-crop-(option)][-blur(integer)][-bw][-q(integer)].extension
    src = [];
    src.push(image.dirname + image.filename);
    src.push(`${res.width}x${res.height}`);
    if (cropType) {
      src.push(`crop-${cropType}`);
    }
    if (blur) {
      src.push(`blur${blur}`);
    }
    if (bw) {
      src.push('bw');
    }
    if (quality) {
      src.push(`q${quality}`);
    }
    res.src = `${src.join('-')}.${extension}`;
    return res;
  }

  /**
   * same signatur as get()
   * @param {Object} image
   * @param {Array} config
   * @param {mixed} hiRes
   * @returns {Promise}
   */
  preload (image, config, hiRes) {
    return new Promise((resolve, reject) => {
      let file = this.get(image, config, hiRes);
      if (isObject(file)) {
        let Preload = new Image();
        Preload.onload = resolve;
        Preload.onerror = reject;
        Preload.src = file.src;
      } else {
        reject(new Error('no valid image given'));
      }
    });
  }

  /**
   * @param {string} url, the url of the sprite fie
   * @param {string} nodeId, the ID of the HTML-node to add the sprite to
   * @returns {Promise}
   */
  loadSprite (url, nodeId) {
    return api.static(url)
      .then(response => {
        let node = document.getElementById(nodeId);
        if (node) {
          node.innerHTML = response.data;

          // Build a map with viewBox attributes for each svg
          let svgs = node.querySelectorAll('symbol');
          svgs.forEach(svg => {
            const name = svg.getAttribute('id');
            this.viewBox[name] = svg.getAttribute('viewBox');
          });
        }
      });
  }

  getViewBox (name) {
    if (hasKey(this.viewBox, name)) {
      return this.viewBox[name];
    }
    return '0 0 300 300';
  }

  /*
  |--------------------------------------------------------------------------
  | Helper
  |--------------------------------------------------------------------------
  */

  /**
   * Get Image dimensions
   * @param {Object} orig, width and height of original
   * @param {mixed} max, maximum width and height
   * @param {boolean} crop, crop image
   * @param {boolean} hiRes, for hiRes displays
   * @returns {Object}
   */
  _calculateDimensions (orig, max, crop, hiRes) {
    let res = {
      width: null,
      height: null,
      ratio: round(orig.width / orig.height, 4),
    };

    // keep ratio, limit height to maxHeight
    if (max.width == null) {
      res.width = round(max.height * res.ratio, 0);
      res.height = max.height;

      // keep ratio, limit width to max.width
    } else if (max.height == null) {
      res.width = max.width;
      res.height = round(max.width / res.ratio, 0);

      // crop to fit in max.width and max.height
    } else if (crop) {
      res.width = max.width;
      res.height = max.height;

      // keep ratio, fit either max.width or max.height
    } else {
      res.width = round(max.height * res.ratio, 0);
      if (res.width <= max.width) {
        res.height = max.height;
      } else {
        res.width = max.width;
        res.height = round(max.width / res.ratio, 0);
      }
    }

    // double resolution for hiRes displays
    if (hiRes) {
      res.width *= 2;
      res.height *= 2;
    }

    // correct the dimensions to not be bigger than original
    // bigger 1 means dimension is bigger than original
    if ((res.width / orig.width) > 1 || (res.height / orig.height) > 1) {

      // take orig.width and calculate height
      if ((res.width / orig.width) >= (res.height / orig.height)) {
        if (max.height == null) {
          res.height = orig.height;
        } else {
          res.height = round(orig.width * res.height / res.width, 0, 'down');
        }
        res.width = orig.width;

        // take orig.height and calculate width
      } else {
        if (max.width == null) {
          res.width = orig.width;
        } else {
          res.width = round(orig.height * res.width / res.height, 0, 'down');
        }
        res.height = orig.height;
      }
    }
    return res;
  }

  /*
   | check for high resolution (e.g. retina display)
   */
  _isHiRes () {
    return window.devicePixelRatio > 1;
  }

}

export default new ImagePlugin();
