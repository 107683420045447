<template lang="pug">
  .cw-cta
    p.hint {{getHint}}
    cw_button.formbutton(:to="getLinkTo" tag="a" :label="getLinkLabel" :icon="true" :extern="getIsExtern" :blank="getIsExtern" :color="getButtonColor")
      include ../../assets/svg/arrow-small.svg
    template(v-if="getLinkToOffice")
      cw_button.formbutton(:to="getLinkToOffice" tag="a" :label="getLinkToOfficeLabel" :icon="true" :extern="true" :blank="true" :color="getButtonColor")
        include ../../assets/svg/arrow-small.svg
    template(v-if="getLinkToExtern")
      a.smallextern(:href="getLinkToExtern" target="_blank") {{ getLinkToExternLabel }}


</template>

<style lang="sass" scoped>
@import '../../styles/variables'
@import '../../lib/core/sass/bp'

.cw-cta
  position: fixed
  background-color: white
  bottom: 0
  right: 0
  width: auto
  text-align: left
  border-top-left-radius: 8px
  box-shadow: 0 0 12px rgba(0, 0, 0, .10)
  padding: 4px 12px 12px
  +bpw(lg)
    padding: 10px 24px 16px

  a
    display: block
    margin-bottom: 2px
    &.smallextern
      color: $color-green
      margin-top: 10px
      text-decoration: none
      transition: all 0.5s ease-out
      &:hover
        color: $color-lila
        text-decoration: underline

  p
    padding: 0
    margin: 0
    font-family: $font-secondary
    font-size: 13px
    margin-bottom: 6px
    +bpw(lg)
      font-size: 14px

  &::v-deep
    a.icon span.svg
      padding: 0
      line-height: 0
      font-size: 0

    a.icon svg
      width: 13px
      height: 28px

    a.icon > span
      padding: 0 12px
      line-height: 28px

    a.icon > span > span
      font-size: 12px

    +bpw(lg)
      a.icon svg
        width: 16px
        height: 14px
      a.icon > span
        line-height: 36px
        padding: 0 14px
      a.icon > span > span
        font-size: inherit

</style>

<script>
import {mapGetters} from 'vuex';
import cw_button from './cw_button';
import {colors} from '../../config/style.json';

export default {
  name: 'CwCta',
  components: {
    cw_button,
  },
  props: {},
  data () {
    return {};
  },
  computed: {
    ...mapGetters('inreal/kirbyApi', [
      'getSiteData',
      'getPageData',
    ]),
    getFooterNavigation () {
      return this.getSiteData.navigation.footer;
    },
    getLinkToOffice () {
      let returnValue = null;
      if (this.getSiteData.cta.officelink != null && this.getSiteData.cta.officelink !== '') {
        returnValue = this.getSiteData.cta.officelink;
      }
      return returnValue;
    },
    getLinkToExtern () {
      let returnValue = null;
      if (this.getSiteData.cta.externlink != null && this.getSiteData.cta.externlink !== '') {
        returnValue = this.getSiteData.cta.externlink;
      }
      return returnValue;
    },
    getLinkToOfficeLabel () {
      let returnValue = null;
      if (this.getSiteData.cta.officelabel != null && this.getSiteData.cta.officelabel !== '') {
        returnValue = this.getSiteData.cta.officelabel;
      }
      return returnValue;
    },
    getLinkToExternLabel () {
      let returnValue = null;
      if (this.getSiteData.cta.externlabel != null && this.getSiteData.cta.externlabel !== '') {
        returnValue = this.getSiteData.cta.externlabel;
      }
      return returnValue;
    },
    getLinkTo () {
      let returnValue = '';
      if (this.getSiteData.cta.link.value !== '') {
        returnValue = this.getSiteData.cta.link.value;
      } else if (this.getSiteData.cta.page != null) {
        returnValue = this.getSiteData.cta.page.value;
      }
      return returnValue;
    },
    getLinkLabel () {
      return this.getSiteData.cta.label;
    },
    getIsExtern () {
      return this.getSiteData.cta.link.value !== '';
    },
    getHint () {
      return this.getSiteData.cta.hint;
    },
    getButtonColor () {
      return colors.yellow;
    },
  },
  watch: {},
  mounted () {
    console.log(this.getPageData);
  },
  beforeDestroy () {
  },
  methods: {},
};
</script>
