/**
 * Mixin that provides the basic implementation for disposable objects. If your
 * class holds references or resources that can't be collected by standard GC,
 * it should use this mixin.
 *
 * @param {*} baseClass
 * @returns {*}
 */
export const disposable = baseClass => class extends baseClass {
  constructor (...args) {
    super(...args);

    /**
     * @type {boolean}
     * @private
     */
    this._disposed = false;
  }

  /**
   * @returns {boolean}
   */
  get isDisposed () {
    return this._disposed;
  }

  /**
   * This method should not be overridden.
   */
  dispose () {
    if (!this._disposed) {
      this._disposed = true;
      this.disposeInternal();
    }
  }

  /**
   * Override this method to implement internal dispose functionality.
   */
  disposeInternal () {
  }
};
