import Vue from 'vue';

import dom from './api/plugins/dom';
import image from './api/plugins/image';
import meta from './api/plugins/meta';

import {breakpoints} from './config/style.json';
import imagesConfig from './config/images.json';

import api from './api';
import store from './store';
import router from './router';
import i18n from './lang';

import vuetify from './plugins/vuetify';
import * as CfConsentManager from './lib/consent-manager/';

import {setLanguageDimension} from './tracking/index';

Vue.use(dom, {
  breakpoints,
  loadGlobalElements: true,
});
Vue.use(api, {
  store,
  i18n,
});
Vue.use(image, {
  images: imagesConfig,
  breakpoints,
});
Vue.use(meta);
Vue.config.productionTip = false;
Vue.use(CfConsentManager, {store});

store.commit('app/setBreakpoints', breakpoints);
store.dispatch('app/updateDocumentDimension').catch(error => {
  console.error(error);
});
store.dispatch('app/updateIsTouchDevice').catch(error => {
  console.error(error);
});

window.addEventListener('resize', () => {
  store.dispatch('app/updateDocumentDimension').catch(error => {
    console.error(error);
  });
});

Promise.resolve()
  .then(() => {
    return store.dispatch('inreal/kirbyApi/initLanguages');
  })
  .then(() => {
    return Promise.all([
      store.dispatch('inreal/kirbyApi/initSite'),
    ]);
  })
  .then(() => {
    new Vue({
      router: router.component(),
      store,
      i18n,
      vuetify,
      computed: {
        lang () {
          return store.state['inreal/kirbyApi'].lang;
        },
      },
      watch: {
        lang: newValue => {
          setLanguageDimension(newValue);
        },
      },
      created () {
        setLanguageDimension(this.lang);
      },
      template: '<router-view></router-view>',
    }).$mount('#page');
  })
  .catch(reason => {
    console.error(reason);
  });
