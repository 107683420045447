<template lang="pug">
  article
    transition(appear appear-active-class="fade-enter-active")
      .inner
        .center
          h1(v-html="modal.content.headline")
          template(v-if="modal.content.logo && modal.content.logo.value !== ''")
            img(:src="modal.content.logo.value")
          template(v-for="text in modal.content.copy")
            component(v-html="text.html" :is="text.tag")
          template(v-if="modal.content.link && modal.content.link.value !== ''")
            a(:href="modal.content.link.value")
</template>

<style lang="sass" scoped>
@import '../../styles/variables'
@import '../../lib/core/sass/bp'

.fade-enter-active
  animation: go 0.75s


@keyframes go
  from
    opacity: 0
    transform: translateY(20px)
  to
    opacity: 1
    transform: translateY(0px)

article
  position: relative
  margin: 0
  width: 100%
  height: 100vH
  background-color: transparent
  overflow: auto
  box-sizing: border-box
  display: flex
  align-items: center
  justify-content: center
  flex-direction: column
  text-align: center
  pointer-events: none

.inner
  position: absolute
  border-radius: 8px
  max-width: 670px
  overflow: hidden
  background-color: white
  cursor: default
  pointer-events: all

.center
  h1
    text-align: left
    font-size: 16px
    margin-bottom: 12px
    margin-top: 0px
  padding: 32px
  p
    text-align: left
    color: $color-grey-dark
    margin-bottom: 16px
  p::v-deep
    a, a:hover, a:visited
      color: $color-red
    strong
      font-weight: bold
  img
    width: auto
    height: 100%
    max-height: 40px
    margin-bottom: 16px
    display: block
    +bpw(xl)
      max-height: 50px
      margin-bottom: 32px
    +bpw(hg)
      max-height: 64px
</style>

<script>
import CwPicture from '../elements/cw_picture';

export default {
  name: 'CwModalInfo',
  components: {
    CwPicture,
  },
  props: {
    modal: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
    };
  },
  computed: {
  },
  watch: {},
  mounted () {
  },
  beforeDestroy () {},
  methods: {
    $_closeModal () {
      this.$store.commit('inreal/kirbyApi/unsetModalData');
    },
  },
};
</script>
