import {listen} from '../../../lib/core/js/events';

/**
 * Resolves as soon as the document loading state is interactive or complete.
 *
 * @param {?Document=} doc The document to check, by default the current document
 * @returns {Promise<void>}
 */
export function onDomContentLoaded (doc = document) {
  return new Promise(resolve => {
    if (doc.readyState === 'interactive'
      || doc.readyState === 'complete') {
      resolve();
    } else {
      const remove = listen(doc, 'readystatechange', () => {
        if (doc.readyState === 'interactive') {
          remove();
          resolve();
        }
      });
    }
  });
}

/**
 * Resolves as soon as the document loading state is complete.
 *
 * @param {?Document=} doc The document to check, by default the current document
 * @returns {Promise<void>}
 */
export function onDocumentLoaded (doc = document) {
  return new Promise(resolve => {
    if (doc.readyState === 'complete') {
      resolve();
    } else {
      const remove = listen(doc, 'readystatechange', () => {
        if (doc.readyState === 'complete') {
          remove();
          resolve();
        }
      });
    }
  });
}
