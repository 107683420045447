<template lang="pug">
  component(
    :is="tag"
    :href="to"
    :class="{icon, small, inactive, outline, inline, hasHoverColor: color === '#ef4d4b'}"
    @click="$_doClick($event)"
    :style="getColorStyle"
    :target="getTarget"
  )
    span
      span.svg
        slot
      span {{label}}
</template>

<style lang="sass" scoped>
@import '../../styles/variables'
@import '../../lib/core/sass/bp'

a, button
  cursor: pointer
  display: inline-block
  vertical-align: middle
  text-decoration: none
  color: black
  font-family: $font-main
  font-weight: 600
  overflow: hidden
  position: relative
  background-color: $color-grey-warm-dark
  box-shadow: 0 0 10px rgba($color-bronze-dark, 0)
  box-sizing: border-box
  border-radius: 4px
  fill: #ffffff
  transition: all 0.45s
  &:focus
    outline: none
    box-shadow: 0 0 5px rgba($color-yellow, 0.75)
  &:before
    content: ''
    position: absolute
    left: 0
    top: 0
    right: 100%
    height: 100%
    background-color: $color-yellow
    transition: right 0.45s
  > span
    position: relative
    display: block
    line-height: 36px
    padding: 0 14px
    text-transform: uppercase
    transition: color 0.45s
    > span
      display: inline-block
      vertical-align: middle
    svg
      polygon
        transition: fill 0.45s
  &:hover
    box-shadow: 0 0 10px rgba($color-grey-warm-dark, 0.45)
    &:before
      right: 0
  &.hasHoverColor
    &:before
      background-color: $color-lila
  &.inline
    &:hover
      box-shadow: 0 0 10px rgba($color-grey-warm-dark, 0)
      svg
        fill: $color-yellow
      > span
        color: $color-yellow
    &:before
      background-color: transparent
    > span
      padding-left: 0px !important
  &.outline
    &:hover
      svg
        polygon
          transition: fill 0.45s
          fill: white !important
      > span
        color: white
  &.icon
    span > span:first-child
      margin-right: 8px
      display: inline-block
      vertical-align: middle
    svg
      width: 16px
      height: 14px
  &.small
    > span
      line-height: 26px
      padding: 0 12px
      font-size: 14px
    &.icon
      > span
        padding-left: 6px
      span > span:first-child
        margin-right: 8px
  &.inactive
    &:hover
      &:before
        right: 100%
</style>

<script>
export default {
  name: 'CwButton',
  components: {},
  props: {
    tag: {
      type: String,
      default: 'button',
    },
    label: {
      type: String,
      default: 'Weiter',
    },
    to: {
      type: String,
      default: '',
    },
    extern: {
      type: Boolean,
      default: false,
    },
    blank: {
      type: Boolean,
      default: false,
    },
    modal: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    outline: {
      type: Boolean,
      default: false,
    },
    inactive: {
      type: Boolean,
      default: false,
    },
    inactiveColor: {
      type: String,
      default: '#929292',
    },
    color: {
      type: String,
      default: '#666462',
    },
    inline: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {};
  },
  computed: {
    getColorStyle () {
      if (this.inactive) {
        return `background-color: ${this.inactiveColor}; border-color: ${this.inactiveColor}`;
      } else {
        if (this.outline) {
          return `fill: ${this.color}; color: ${this.color}; background-color: transparent; border: 1px solid ${this.color}`;
        } else if (this.inline) {
          return `fill: ${this.color}; color: ${this.color}; background-color: transparent;`;
        } else {
          return `background-color: ${this.color};`;
        }
      }
    },
    getTarget () {
      return this.blank ? '_blank' : '_self';
    },
  },
  watch: {},
  mounted () {
  },
  beforeDestroy () {
  },
  methods: {
    $_doClick (event) {
      if (this.inactive) {
        event.preventDefault();
        event.stopPropagation();
      } else {
        if (this.tag === 'a') {
          if (!this.extern) {
            event.preventDefault();
            event.stopPropagation();
            if (this.modal) {
              this.$router.loadModal(this.to);
            } else {
              this.$router.push(this.to);
            }
          }
        } else {
          this.$emit('click', event);
        }
      }
    },
  },
};
</script>
