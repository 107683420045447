import api from '../../api/index';
import {hasKey} from '../../lib/core/js/collections';
import {toInteger} from '../utils/convert';

const state = {
  api: null,
  lang: null,
  i18n: null,
  pagekey: null,
  status: {
    site: null,
    page: null,
    modal: null,
  },
  languages: {},
  navigation: {},
  site: {},
  page: {},
  children: {},
  modal: {},
};

const getters = {
  getApiRoute: state => {
    return state.api;
  },
  getLanguages: state => {
    return state.languages;
  },
  getLangKey: state => {
    return state.lang;
  },
  getCurrentLanguage: state => property => {
    let language = state.languages[state.lang];
    return property ? language[property] : language;
  },
  getDefaultLanguage: state => {
    let lang;
    Object.keys(state.languages).forEach(key => {
      const language = state.languages[key];
      if (language.default) {
        lang = key;
      }
    });
    return lang;
  },
  getIsMultiLanguage: state => {
    return Object.keys(state.languages).length > 1;
  },
  getSiteData: state => {
    return state.site;
  },
  getSiteError: state => {
    return state.status.site !== 200;
  },
  getSiteStatus: state => {
    return state.status.site;
  },
  getPageData: state => {
    return state.page;
  },
  getModalData: state => {
    return state.modal;
  },
  getPageError: state => {
    return state.status.page !== 200 || state.status.site !== 200;
  },
  getPageBlueprint: state => {
    return state.page.head.blueprint;
  },
  getPageStatus: state => {
    return state.status.page;
  },
  getModalStatus: state => {
    return state.status.modal;
  },
  getNavigationData: state => {
    return state.navigation;
  },
};

const mutations = {
  setEnviromentVars (state) {
    state.api = '/cs/api';
  },
  setLanguage (state, lang) {
    state.lang = lang;
  },
  setLanguages (state, response) {
    state.languages = response.data;
  },
  setSiteData (state, response) {
    state.status.site = toInteger(response.status);
    state.site = {};
    Object.keys(response.data).forEach(key => {
      const values = response.data[key];
      if (key === 'navigation') {
        state.navigation = values;
      } else {
        state.site[key] = values;
      }
    });
    state.site = response.data;
  },
  setPageData (state, response) {
    state.status.page = toInteger(response.status);
    state.pagekey = response.pagekey;
    if (hasKey(response, 'data')) {
      state.page = response.data;
    } else {
      state.page = {};
    }

    // put children in extra node
    state.children = {};
    if (hasKey(state.page, 'children')) {
      state.page.children.forEach(child => {
        state.children[child.head.id] = child;
      });
    }

    // avoid meta errors on error pages
    if (!hasKey(response.data, 'meta')) {
      state.page.meta = {
        title: '',
        description: [],
        keywords: [],
        sitemappriority: 5,
        type: 'website',
        image: null,
      };
    }
  },
  setModalData (state, response) {
    state.status.modal = toInteger(response.status);
    if (hasKey(response, 'data')) {
      state.modal = response.data;
    } else {
      state.modal = {};
    }
  },
  unsetModalData (state) {
    state.status.modal = null;
    state.modal = {};
  },
};

const actions = {
  initLanguages: ({commit}) => {
    return api.languages()
      .then(response => {
        commit('setLanguages', response);
        Object.keys(response.data).forEach(lang => {
          const language = response.data[lang];
          if (language.default) {
            api.setLocaleFallback(lang);
          }
        });
      })
      .catch(reason => {
        return Promise.reject(reason);
      });
  },

  initSite: ({dispatch, state}) => {
    let lang = api.detectLanguage(state.languages);
    if (!api.isLanguageValid(lang, state.languages)) {
      return Promise.reject(new Error('Fatal error: no valid language detected'));
    }
    return dispatch('loadSite', {lang});
  },

  loadLanguage: ({dispatch, state}, {lang}) => {
    if (lang === state.lang) {
      return Promise.resolve();
    } else if (!api.isLanguageValid(lang, state.languages)) {
      return Promise.reject(new Error('Fatal error: language not valid'));
    } else {
      return dispatch('loadSite', {lang});
    }
  },

  /*
   | Load navigation, site-meta etc. for a specific language
   */
  loadSite: ({commit}, {lang}) => {
    return Promise.all([
      api.site(lang),
      api.translations(lang),
    ])
      .then(([dataSite, dataTranslations]) => {
        commit('setLanguage', lang);
        commit('setSiteData', dataSite);
        api.setLocale(lang, dataTranslations.data);
      })
      .catch(reason => {
        return Promise.reject(reason);
      });
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
