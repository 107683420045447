/**
 * Creates a Promise that resolves after a certain event type is triggered.
 *
 * @param {EventTarget} eventTarget
 * @param {string} eventType
 * @param {*=} options
 * @returns {Promise<Event>}
 */
export function waitForEvent (eventTarget, eventType, options = null) {
  return new Promise(resolve => {
    const remove = listen(eventTarget, eventType, event => {
      remove();
      resolve(event);
    }, options);
  });
}

/**
 * Adds an event listener to an event target and returns a function that
 * when invoked removes that exact event listener from the event target.
 *
 * @param {EventTarget} eventTarget
 * @param {string} eventType
 * @param {*} listener
 * @param {*=} options
 * @returns {function():void}
 */
export function listen (eventTarget, eventType, listener, options = null) {
  if (options == null) {
    eventTarget.addEventListener(eventType, listener);
  } else {
    eventTarget.addEventListener(eventType, listener, options);
  }

  return () => {
    if (options == null) {
      eventTarget.removeEventListener(eventType, listener);
    } else {
      eventTarget.removeEventListener(eventType, listener, options);
    }
  };
}
