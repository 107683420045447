<template lang="pug">
  .cw-privacy
    .content(v-if="page != null")
      h1 {{page.content.headline}}
      template(v-for="item in page.content.copybefore")
        component(:is="item.tag" v-html="item.html")
      p
        a(href="#" @click.prevent="$emit('edit-settings-click')") {{$t('legal.editcookie')}}
      template(v-for="item in page.content.copyafter")
        component(:is="item.tag" v-html="item.html")
</template>

<style lang="sass" scoped>
@import '../../styles/variables'
@import '../../lib/core/sass/bp'

h1
  color: $color-grey-warm-dark
  font-size: 16px
  line-height: 20px
  margin-bottom: 16px
  margin-top: 16px
p
  margin-bottom: 16px
  color: $color-grey-warm-dark
  &::v-deep
    strong
      font-family: $font-main
      text-transform: uppercase
ul
  padding: 0 16px
  font-family: $font-secondary
  font-weight: 400
  line-height: 28px
  font-size: 16px
  color: $color-bronze
  margin-bottom: 16px

</style>

<script>
import {mapGetters} from 'vuex';
import api from '../../api';

export default {
  name: 'CwPrivacy',
  components: {},
  props: {},
  data () {
    return {
      page: null,
    };
  },
  computed: {
    ...mapGetters('inreal/kirbyApi', [
      'getSiteData',
      'getLangKey',
    ]),
  },
  watch: {},
  mounted () {
    api.node(this.getSiteData.pages.dataprotection.slug, '', this.getLangKey)
      .then(response => {
        this.page = response.data;
      })
      .catch(reason => {
        return Promise.reject(reason);
      });
  },
  beforeDestroy () {
  },
  methods: {
  },
};
</script>
