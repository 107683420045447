/**
 * @returns {?string}
 */
export function getBrowserLanguage () {
  if (!navigator.language) {
    return null;
  }

  const reg = new RegExp(/^[a-zA-Z]{2,3}/);
  const result = reg.exec(navigator.language)[0];

  if (result != null) {
    return result;
  } else {
    return null;
  }
}
