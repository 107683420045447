/**
 * @param {*} value
 * @returns {boolean}
 */
export function isMap (value) {
  return value instanceof Map;
}

/**
 * Filter over a map.
 *
 * The returned map is a new map with all filtered elements.
 *
 * @param {Map} map
 * @param {function(*, *, Map):boolean} predicate
 * @returns {Map}
 */
export function filter (map, predicate) {
  const result = new Map();
  map.forEach((value, key, map) => {
    if (predicate(value, key, map) === true) {
      result.set(key, value);
    }
  });
  return result;
}

/**
 * Returns the first entry in the map
 * that satisfies the provided testing function.
 *
 * @param {Map} map
 * @param {function(*, *, Map):boolean} predicate
 * @returns {Array}
 */
export function findEntry (map, predicate) {
  let result = null;
  map.forEach((value, key, map) => {
    if (result == null
      && predicate(value, key, map) === true) {
      result = [key, value];
    }
  });
  return result;
}
