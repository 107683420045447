<template lang="pug">
  span.cf-hamburger
    span.wrapper(:class="classes")
      span.top-line(:style="getSpanStyle")
      span.center-line(:style="getSpanStyle")
      span.bottom-line(:style="getSpanStyle")
</template>

<style lang="sass" scoped>
.cf-hamburger
  display: block
  position: relative

  width: 30px
  height: 20px

  .wrapper
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    pointer-events: none
    transition: all .3s ease-out

    span
      position: absolute
      display: block
      transition: all .3s ease-out
      width: 100%
      left: 0

  .top-line
    top: 0

  .center-line
    top: 50%
    transform: translateY(-50%)

  .bottom-line
    bottom: 0

  .wrapper.close
    transform: rotate(135deg)

    .top-line
      top: 50%
      transform: translateY(-50%) rotate(90deg)

    .center-line
      opacity: 0

    .bottom-line
      bottom: 50%
      transform: translateY(50%)

  .wrapper.arrow-left
    transform: rotate(-180deg)

    .top-line
      width: 60%
      top: 50%
      left: 40%
      transform: rotate(45deg) translateX(0%) translateY(-50%)
      transform-origin: right center

    .bottom-line
      width: 60%
      bottom: 50%
      left: 40%
      transform: rotate(-45deg) translateX(0%) translateY(50%)
      transform-origin: right center

  .wrapper.arrow-right
    transform: rotate(180deg)

    .top-line
      width: 60%
      top: 50%
      transform: rotate(-45deg) translateX(0%) translateY(-50%)
      transform-origin: left center

    .bottom-line
      width: 60%
      bottom: 50%
      transform: rotate(45deg) translateX(0%) translateY(50%)
      transform-origin: left center
</style>

<script>

/**
 * Only one of [close], [arrowLeft] and [arrowRight] may be active at a given time.
 */
export default {
  name: 'CfHamburger',
  components: {},
  props: {
    thickness: {
      type: Number,
      default: 2,
    },
    borderRadius: {
      type: Number,
      default: 0,
    },
    color: {
      type: String,
      default: 'white',
    },
    close: {
      type: Boolean,
      default: false,
    },
    arrowLeft: {
      type: Boolean,
      default: false,
    },
    arrowRight: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {};
  },
  computed: {
    classes () {
      return {
        'close': this.close,
        'arrow-left': this.arrowLeft,
        'arrow-right': this.arrowRight,
      };
    },
    getSpanStyle () {
      return {
        'background-color': this.color,
        'height': `${this.thickness}px`,
        'border-radius': `${this.borderRadius}px`,
      };
    },
  },
  watch: {},
  mounted () {
  },
  beforeDestroy () {
  },
  methods: {},
};
</script>
