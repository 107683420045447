import {isArray} from '../array';
import {isMap} from '../map';

/**
 * @param {*} value
 * @returns {boolean}
 */
export function isObject (value) {
  let type = typeof value;
  return value
    && type
    && type === 'object'
    && !isArray(value)
    && !isMap(value);
}
