/**
 * Returns a Promise that resolves after a certain amount of time.
 *
 * @param {number} milliseconds
 * @returns {Promise<void>}
 */
export function waitFor (milliseconds) {
  return new Promise(resolve => {
    setTimeout(resolve, milliseconds);
  });
}

/**
 * Returns a function, that, as long as it continues to be invoked, will not
 * be triggered. The function will be called after it stops being called for
 * N milliseconds. If `immediate` is passed, trigger the function on the
 * leading edge, instead of the trailing.
 *
 * @param {Function} func
 * @param {number} milliseconds
 * @param {boolean=} immediate
 * @returns {Function}
 */
export function debounce (func, milliseconds, immediate = false) {
  let timeout = null;
  return function (...args) {
    const context = this;

    function later () {
      timeout = null;
      if (!immediate) {
        func.apply(context, args);
      }
    }

    const callImmediately = immediate && timeout == null;

    if (timeout != null) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(later, milliseconds);

    if (callImmediately) {
      func.apply(context, args);
    }
  };
}
