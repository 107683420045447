import Vue from 'vue';
import VueI18n from 'vue-i18n';
import {CfConsentManagerI18n} from '../lib/consent-manager';

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: null,
  fallbackLocale: null,
  messages: CfConsentManagerI18n,
  numberFormats: {
    de: {
      // https://kazupon.github.io/vue-i18n/guide/number.html#custom-formatting
      // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/NumberFormat
      integer: {
        style: 'decimal',
        maximumFractionDigits: 0,
      },
      float: {
        style: 'decimal',
        maximumFractionDigits: 2,
      },
      percent: {
        style: 'percent',
        minimumFractionDigits: 1,
      },
      percentNoFraction: {
        style: 'percent',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      },
      currency: {
        style: 'currency',
        currency: 'EUR',
      },
      currencyNoFraction: {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      },
    },
  },
  dateTimeFormats: {
    de: {
      // https://kazupon.github.io/vue-i18n/guide/datetime.html
      // http://www.ecma-international.org/ecma-402/2.0/#sec-intl-datetimeformat-constructor
      shortDate: {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric',
      },
      shortDateNoYear: {
        day: 'numeric',
        month: 'numeric',
      },
      longDate: {
        weekday: 'long',
        day: 'numeric',
        month: 'long',
        year: 'numeric',
      },
      shortDateTime: {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      },
      longDateTime: {
        weekday: 'long',
        day: 'numeric',
        month: 'long',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      },
      time: {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      },
    },
  },
  missing: (locale, key) => {
    return `#${locale}:${key}#`;
  },
});

export default i18n;
