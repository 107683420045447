import {identity, identical, trueFn} from '../functions';

/**
 * @param {*} value
 * @returns {boolean}
 */
export function isArray (value) {
  return Array.isArray(value);
}

/**
 * Removes all holes from an array.
 *
 * See {@link https://tinyurl.com/2ality-holes-arrays} for more details.
 *
 * @param {Array} array
 * @returns {Array}
 */
export function removeHoles (array) {
  return array.filter(trueFn);
}

/**
 * Calls an async function for each element in an array. Subsequent
 * functions are only executed after the Promise returned by the
 * preceding function resolves.
 *
 * The returned Promise resolves after all async functions for every
 * element have resolved.
 *
 * @param {Array<T>} array
 * @param {function(T, number, Array<T>): Promise} action
 * @returns {Promise}
 * @template T
 */
export function asyncForEach (array, action) {
  return array.reduce((promise, element, index, arr) => {
    return promise.then(() => action(element, index, arr));
  }, Promise.resolve());
}

/**
 * Calls an async function for each element in an array, starting from
 * the last element rather than the first. Subsequent functions are only
 * executed after the Promise returned by the preceding function resolves.
 *
 * The returned Promise resolves after all async functions for every
 * element have resolved.
 *
 * @param {Array<T>} array
 * @param {function(T, number, Array<T>): Promise} action
 * @returns {Promise}
 * @template T
 */
export function asyncForEachRight (array, action) {
  return array.reduceRight((promise, element, index, arr) => {
    return promise.then(() => action(element, index, arr));
  }, Promise.resolve());
}

/**
 * Pushs an element into an array if it is not already present.
 *
 * @param {array} array The array to push into
 * @param {*} element The element to add
 * @param {(function(*, number, Array):boolean)=} predicate Optional predicate function to check for the element in the array
 * @returns {boolean} If the element was found or not
 */
export function pushIfNotPresent (array, element, predicate) {
  const elementFound = predicate != null ?
    array.find(predicate) != null
    : array.includes(element);

  if (elementFound === false) {
    array.push(element);
  }

  return elementFound;
}

/**
 * Remove the first occurrence of an element in an array if it is present.
 *
 * @param {array} array The array to remove from
 * @param {*} element The element to remove
 * @param {(function(*, number, Array):boolean)=} predicate Optional predicate function to check for the element in the array
 * @returns {boolean} If the element was found or not
 */
export function removeIfPresent (array, element, predicate) {
  let predicateFn = predicate;

  if (predicateFn == null) {
    predicateFn = e => e === element;
  }

  const foundIndex = array.findIndex(predicateFn);
  const elementFound = foundIndex !== -1;

  if (elementFound === true) {
    array.splice(foundIndex, 1);
  }

  return elementFound;
}

/**
 * Pushs or removes an element into/from an array,
 * depending wether [shouldBeInArray] is true or false respectively.
 *
 * If [shouldBeInArray] is true and the element is already in the array
 * this is a noop.
 *
 * Equaly if [shouldBeInArray] is false and the element is not in the array
 * this is also a noop.
 *
 * @param {array} array The array to perform the action on
 * @param {*} element The element to add or remove
 * @param {boolean} shouldBeInArray If the element should be in the array or not
 * @param {(function(*, number, Array):boolean)=} predicate Optional predicate function to check for the element in the array
 * @returns {boolean} If the element was found or not
 */
export function pushOrRemove (array, element, shouldBeInArray, predicate) {
  return shouldBeInArray === true ?
    pushIfNotPresent(array, element, predicate)
    : removeIfPresent(array, element, predicate);
}

/**
 * Removes all duplicates from an array.
 *
 * By default, only works with primitive values.
 * To check for complex types, use the predicate function.
 *
 * @param {Array} array The array to perform the action on
 * @param {(function(*, *):boolean)=} predicate Optional predicate function to check pairs, should return true if items are considered equal
 * @returns {Array} A new array with unique values
 */
export function removeDuplicates (array, predicate) {
  const checkFn = predicate != null
    ? predicate
    : identical;

  const resultArray = [];

  array.forEach(element => {
    const foundElement = resultArray.find(x => {
      return checkFn(element, x);
    });

    if (foundElement == null) {
      resultArray.push(element);
    }
  });

  return resultArray;
}

/**
 * Gets the intersection array of all input arrays.
 *
 * That is, an array of all elements which are inside every input array.
 *
 * Removes duplicates.
 *
 * By default, only works with primitive values.
 * To check for complex types, use the predicate function.
 *
 * @param {Array<Array>} arrays The input arrays to retrieve the intersecting elements from
 * @param {(function(*, *):boolean)=} predicate Optional predicate function to check pairs, should return true if items are considered equal
 * @returns {Array} A new array with all intersecting values
 */
export function intersection (arrays, predicate) {
  const checkFn = predicate != null
    ? predicate
    : identical;

  const [first, ...rest] = arrays;

  const intersection = first.filter(element => {
    return rest.reduce((prev, current) => {
      const foundElement = current.find(currentElement => checkFn(element, currentElement));

      return prev && foundElement != null;
    }, true);
  });

  return removeDuplicates(intersection, checkFn);
}

/**
 * Gets the last element of an array, or null if length is 0.
 *
 * @param {Array} array
 * @returns {*}
 */
export function lastElement (array) {
  if (array.length === 0) {
    return null;
  }

  return array[array.length - 1];
}

/**
 * A default sorting algorithm using direct comparison.
 *
 * @param {*} a
 * @param {*} b
 * @returns {number}
 */
export function defaultSort (a, b) {
  if (a > b) {
    return 1;
  }

  if (a < b) {
    return -1;
  }

  return 0;
}

/**
 * Groups equal elements of an array.
 *
 * By default this uses a strict equality check on every value in the array.
 *
 * A [getKeyFn] function can be provided to decide by which key to group the elements.
 *
 * @param {Array} array The elements to group.
 * @param {(function(*):*)=} getKeyFn An optional function to get the key by which to group elements.
 * @returns {Array<Array<*>>} An array that contains the groups which contain the elements.
 */
export function groupEqualItems (array, getKeyFn) {
  const keyFn = getKeyFn != null
    ? getKeyFn
    : identity;

  /**
   * @type {Map<any, Array>}
   */
  const map = new Map();

  array.forEach(element => {
    const key = keyFn(element);

    if (!map.has(key)) {
      map.set(key, []);
    }

    map.get(key).push(element);
  });

  return Array.from(map.values());
}

/**
 * Sorts an array by multiple factors.
 *
 * Items are only sorted multiple times, if the keys from the previous step contain identical sort indices.
 * These elements are then grouped by identity and every group is then sorted with the next element in the [sortConfig].
 *
 * The [sortConfig] defines by which key to sort and how to sort.
 * It also defines the precedence in which items have to be sorted.
 * Items in the [sortConfig] array at a lower index are considered
 * to have more weight than items at a higher index.
 *
 * Does not alter the original array.
 *
 * @param {Array} elements The elements to sort.
 * @param {Array<{key: string, sortFn: function(*, *):number}>} sortConfig The configuration by which to sort.
 * @returns {Array} A new array with the sorted items.
 */
export function multiSort (elements, sortConfig) {
  // If the sortConfig is empty, return the elements unsorted.
  if (sortConfig.length === 0) {
    return elements;
  }

  /**
   * @param {Array} elements
   * @param {{key: string, sortFn: function(*, *):number}} sortItem
   * @returns {Array}
   */
  function sortBySortItem (elements, sortItem) {
    let copy = Array.from(elements);
    copy.sort((a, b) => {
      return sortItem.sortFn(
        a[sortItem.key],
        b[sortItem.key],
      );
    });

    return copy;
  }

  /**
   * @param {Array<Array>} array
   * @param {number} step
   * @returns {Array}
   */
  function sortArray (array, step) {
    const sortItem = sortConfig[step];
    const sortedArray = sortBySortItem(array, sortItem);
    const newStep = step + 1;

    // If there are more steps to sort, group the elements and then sort the groups
    if (newStep < sortConfig.length) {
      const groups = groupEqualItems(sortedArray, element => element[sortItem.key]);

      // If the groups have the same length as the sorted array,
      // this means there are no identical sort indices.
      // In this case return the original sorted array directly.
      if (groups.length === sortedArray.length) {
        return sortedArray;
      } else {
        // Sort the groups with identical sort indices.
        return groups
          .map(group => {
            // If a group has only one element, there is nothing to sort.
            // Return the group directly.
            if (group.length === 1) {
              return group;
            } else {
              // Sort the group recursively.
              return sortArray(group, newStep);
            }
          })
          .flat();
      }
    } else {
      return sortedArray;
    }
  }

  return sortArray(elements, 0);
}

export const SORT_ORDER = {
  /**
   * Ascending order.
   */
  ASC: 'asc',
  /**
   * Descending order.
   */
  DESC: 'desc',
};

/**
 * A class that helps with sorting arrays. Supports multisorting.
 */
export class ArraySorter {
  /**
   * @param {boolean} keepConfigOrder If the original order of multi sorted configs should be kept.
   */
  constructor (keepConfigOrder = false) {
    /**
     * @type {boolean}
     * @private
     */
    this._keepConfigOrder = keepConfigOrder;

    /**
     * @type {Array<{key: string, sortOrder: string}>}
     * @private
     */
    this._sortConfigs = [];
  }

  /**
   * Returns true if a sort config with the given key exists.
   *
   * @param {string} key
   * @returns {boolean}
   */
  hasSortConfig (key) {
    return this.getSortConfig(key) != null;
  }

  /**
   * Gets the sort config for the given key.
   *
   * @param {string} key
   * @returns {{key: string, sortOrder: string}|null}
   */
  getSortConfig (key) {
    const index = this._sortConfigs.findIndex(element => element.key === key);

    if (index === -1) {
      return null;
    } else {
      return this._sortConfigs[index];
    }
  }

  /**
   * Sets the sort order for the given key.
   *
   * @param {string} key
   * @param {string} sortOrder
   */
  setSortConfig (key, sortOrder) {
    const index = this._sortConfigs.findIndex(element => element.key === key);
    const found = index !== -1;

    if (found) {
      if (this._keepConfigOrder) {
        this._sortConfigs[index].sortOrder = sortOrder;
      } else {
        this._sortConfigs.splice(index, 1);
        this._sortConfigs.push({
          key,
          sortOrder,
        });
      }
    } else {
      this._sortConfigs.push({
        key,
        sortOrder,
      });
    }
  }

  /**
   * Deletes a sort config by key.
   *
   * @param {string} key
   */
  deleteSortConfig (key) {
    const index = this._sortConfigs.findIndex(element => element.key === key);

    if (index !== -1) {
      this._sortConfigs.splice(index, 1);
    }
  }

  /**
   * @param {string} key
   * @deprecated Use [toggleKey] instead.
   */
  toogleKey (key) {
    this.toggleKey(key);
  }

  /**
   * Toggles the sorting for the given key.
   *
   * If no sorting is set, sets it to ASC.
   * If ASC sorting is set, sets it to DESC.
   * If DESC sorting is set, removes the sorting.
   *
   * @param {string} key
   */
  toggleKey (key) {
    const config = this.getSortConfig(key);

    if (config != null) {
      switch (config.sortOrder) {
        case SORT_ORDER.ASC: {
          this.setSortConfig(key, SORT_ORDER.DESC);
          break;
        }

        case SORT_ORDER.DESC: {
          this.deleteSortConfig(key);
          break;
        }
      }
    } else {
      this.setSortConfig(key, SORT_ORDER.ASC);
    }
  }

  /**
   * Same as [toggleKey] but removes all other key sortings.
   *
   * @param {string} key
   */
  toggleKeyWithReset (key) {
    const config = this.getSortConfig(key);
    this.reset();
    if (config != null) {
      this.setSortConfig(config.key, config.sortOrder);
    }
    this.toggleKey(key);
  }

  /**
   * Resets all sort config, in other words deletes all sorting.
   */
  reset () {
    this._sortConfigs = [];
  }

  /**
   * Sorts the given array with the current sort config.
   *
   * Always returns a new array and does not alter the original array.
   *
   * @param {Array} array The array to sort.
   * @returns {Array} A new array that is sorted.
   */
  sort (array) {
    return multiSort(array, this._sortConfigs.map(element => {
      return {
        key: element.key,
        sortFn: element.sortOrder === SORT_ORDER.ASC
          ? defaultSort
          : (a, b) => defaultSort(b, a),
      };
    }));
  }
}
