import image from './image';
import {hasKey} from '../../lib/core/js/collections';
import {isObject} from '../../lib/core/js/object';
import {htmlToText, toString, escape, toInteger, toUri} from '../utils/convert';
import {isString} from '../utils/validate';

class MetaPlugin {

  /*
  |--------------------------------------------------------------------------
  | Register
  |--------------------------------------------------------------------------
  */

  install (Vue) {
    this.Vue = Vue;
    Vue.prototype.$metafields = {
      setPage: (site, page, lang) => {
        return this.setPage(site, page, lang);
      },
      setError: errno => {
        return this.setError(errno);
      },
    };
  }

  /*
  |--------------------------------------------------------------------------
  | Interface
  |--------------------------------------------------------------------------
  */

  setPage (site, page, lang) {
    if (!hasKey(site, 'meta')) {
      throw (new Error({status: 1001, msg: 'site-data for default language not set'}));
    }
    if (!hasKey(page, 'meta') || !hasKey(page, 'head')) {
      throw (new Error({status: 1002, msg: 'page-data for default language not set'}));
    }

    let meta = [];

    let title = this._getTitle(
      site.meta.title,
      page.meta.title,
      page.head.title,
      site.meta.titleorder,
    );
    meta.push({
      'tag': 'title',
      'content': title,
    });
    meta.push({
      'property': 'og:title',
      'content': title,
    });

    let description = this._getContent(
      htmlToText(site.meta.description, true),
      htmlToText(page.meta.description, true),
    );
    meta.push({
      'name': 'description',
      'content': description,
    });
    meta.push({
      'property': 'og:description',
      'content': description,
    });
    meta.push({
      'name': 'twitter:card',
      'content': description,
    });


    meta.push({
      'name': 'keywords',
      'content': this._getContent(
        site.meta.keywords.join(', '),
        page.meta.keywords.join(', '),
      ),
    });
    meta.push({
      'name': 'author',
      'content': this._getContent(
        site.meta.author,
      ),
    });
    meta.push({
      'property': 'og:url',
      'content': this._getCurrentUrl(),
    });
    meta.push({
      'property': 'og:type',
      'content': this._getContent(page.meta.type),
    });
    meta.push({
      'property': 'og:locale',
      'content': this._getContent(`${lang.toUpperCase()}_${lang.toLowerCase()}`),
    });

    let image = this._getImage(site, page);
    meta.push({
      'property': 'og:image',
      'content': hasKey(image, 'src') ? image.src : null,
    });
    meta.push({
      'property': 'og:image:width',
      'content': hasKey(image, 'url') ? toString(image.width) : null,
    });
    meta.push({
      'property': 'og:image:height',
      'content': hasKey(image, 'url') ? toString(image.height) : null,
    });
    this._domSetMeta(meta);
  }

  setError (errno) {
    let meta = [];
    meta.push({
      'tag': 'title',
      'content': `Error ${errno}`,
    });
    this._domSetMeta(meta);
  }

  /*
  |--------------------------------------------------------------------------
  | Helper
  |--------------------------------------------------------------------------
  */


  _getTitle (site, page, title, order) {
    return escape(
      order.replace('_', ' | ')
        .replace('site', site)
        .replace('page', isString(page) ? page : title),
    );
  }


  _getContent (val1, val2) {
    if (isString(val2)) {
      return escape(val2, true);
    }
    if (isString(val1)) {
      return escape(val1, true);
    }
    return null;
  }


  _getImage (site, page) {
    let config = [];
    if (
      hasKey(site.api, 'seoImage') &&
      hasKey(site.api.metaImage, 'width') &&
      hasKey(site.api.metaImage, 'height') &&
      hasKey(site.api.metaImage, 'crop')) {
      config = [
        toInteger(site.api.metaImage.width),
        toInteger(site.api.metaImage.height),
        site.api.metaImage.crop,
      ];
    } else {
      config = [1200, 630, true];
    }
    if (isObject(page.meta.image)) {
      return image.get(page.meta.image, config, false, true);
    }
    return image.get(site.meta.image, config, false, true);
  }

  _getCurrentUrl () {
    return toUri(
      window.location.origin,
      window.location.pathname,
    ).substr(1); // workaround, uri should better be checked for http:// etc. before leading slash is added
  }

  _domSetMeta (meta) {
    Object.values(meta).forEach(obj => {
      if (hasKey(obj, 'tag')) {
        let node = document.head.querySelectorAll(obj.tag);
        if (node.length === 1 && node[0].innerHTML !== obj.content) {
          node[0].innerHTML = obj.content;
        }
      } else {
        let node = [];
        if (hasKey(obj, 'name')) {
          node = document.head.querySelectorAll(`meta[name="${obj.name}"]`);
        } else if (hasKey(obj, 'property')) {
          node = document.head.querySelectorAll(`meta[property="${obj.property}"]`);
        }
        if (node.length === 1 && node[0].getAttribute('content') !== obj.content) {
          node[0].setAttribute('content', obj.content);
        }
      }
    });
  }
}

export default new MetaPlugin();
